.tip_HNTSX.tip_HNTSX {
  box-shadow: 0px 8px 24px rgba(var(--glz-color-neutral-tone-5-rgb), 0.16);
}

.wrapper_xDDe7 {
  display: flex;
  flex-direction: column;
  position: relative;
  font-family: Verdana, "Dejavu Sans", Geneva, Tahoma, sans-serif;
  height: fit-content;
  box-sizing: border-box;
  border-radius: 6px;
  min-height: 20px;
}

.header_bCVrI {
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.1px;
  font-weight: normal;
  color: var(--glz-color-neutral-tone-5);
}
.header_bCVrI.small_Lamob {
  font-size: 12px;
  font-weight: normal;
  line-height: 16px;
  color: var(--glz-color-neutral-tone-5);
}
.header_bCVrI.icon_nGVy1 {
  display: flex;
}

.content_aZkFx.text-content_-r3HD {
  font-size: 12px;
  font-weight: normal;
  line-height: 16px;
  color: var(--glz-color-neutral-tone-5);
}
.content_aZkFx.text-header_ITGM5 {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.1px;
  font-weight: normal;
  color: var(--glz-color-neutral-tone-5);
}

.footer_XVYRl {
  display: flex;
  align-items: flex-start;
}
.footer_XVYRl .action_FiHOA:not(:last-child) {
  margin-right: 10px;
}

.action_FiHOA {
  display: inline-flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

.medium_paJKc.with-width_EzvIW {
  width: 388px;
}
.medium_paJKc .header_bCVrI {
  padding: 24px 56px 12px 24px;
}
.medium_paJKc .content_aZkFx {
  padding: 0 24px;
}
.medium_paJKc .footer_XVYRl {
  padding: 12px 24px 24px 24px;
}

.small_Lamob.with-width_EzvIW {
  width: 235px;
}
.small_Lamob .header_bCVrI {
  padding: 16px 46px 12px 16px;
}
.small_Lamob .content_aZkFx {
  padding: 0 16px;
}
.small_Lamob .footer_XVYRl {
  padding: 12px 16px 16px 16px;
}

.header_bCVrI.without-content_k8B33 {
  padding-bottom: 0;
}

.theme-dark_sT6Tu {
  background-color: rgba(var(--glz-color-neutral-tone-5-rgb), 0.9);
}

.theme-yellow_iH5xB {
  background-color: var(--glz-color-warning-tone-3);
}

.theme-green_y3IXM {
  background-color: var(--glz-color-success-tone-3);
}

.theme-light_FFAFv {
  background-color: var(--glz-color-neutral-tone-0);
}

.close-button_R14yd {
  position: absolute;
  right: 24px;
  top: 24px;
  z-index: 1;
  cursor: pointer;
  width: 24px;
  height: 24px;
  border: 0;
  padding: 0;
  background: none;
  color: transparent;
  outline: 0;
  fill: var(--glz-color-neutral-tone-3);
}
.close-button_R14yd.small_Lamob {
  right: 16px;
  top: 16px;
}
.close-button_R14yd:hover {
  fill: var(--glz-color-neutral-tone-4);
}
.close-button_R14yd:focus {
  outline: 0;
}
.close-button_R14yd:focus-visible {
  fill: var(--glz-color-neutral-tone-5);
}
.close-button_R14yd:active {
  fill: var(--glz-color-neutral-tone-5);
}

.is-center-alignment_ABlHS {
  justify-content: center;
}
.is-center-alignment_ABlHS .mobile-footer_Td1u- {
  margin-top: 16px;
}