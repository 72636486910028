.container {
  display: flex;
  padding-right: 16px;
}

@media (max-width: 890px) {
  .container {
    display: none;
  }
}

.link:not(:last-child) {
  margin-right: 12px;
}
