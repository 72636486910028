.counters {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-right: 8px;
  min-width: 16px;
  max-height: 36px;
}

.counter {
  position: relative;
  width: 16px;
  height: 16px;
}

.counter::before {
  display: block;
  content: '';
  width: 8px;
  height: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  background-color: currentColor;
}

.counter_new {
  color: var(--glz-color-success);
}

.counter_updated {
  color: var(--glz-color-warning);
}

.counter_error {
  color: var(--glz-color-error);
}
