.input_A-5bE {
  width: 100%;
  background: none;
  border: 0;
  padding: 5px 0 5px 0;
  font-size: 12px;
  font-weight: normal;
  color: var(--glz-color-neutral-tone-5);
  letter-spacing: -0.1px;
  position: relative;
  line-height: 20px;
  outline: 0;
  font-family: Verdana, "Dejavu Sans", Geneva, Tahoma, sans-serif;
  box-sizing: border-box;
}
.input_A-5bE::placeholder {
  color: var(--glz-color-neutral-tone-3);
}
.input_A-5bE:focus {
  outline: 0;
}