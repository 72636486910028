.content {
  padding-bottom: 24px;
}

.content_hasApps {
  padding-top: 24px;
}

.disclaimer {
  margin: 0;
}

.apps {
  display: flex;
  flex-direction: column;
  gap: 18px;
  margin-top: 8px;
}

.info {
  display: flex;
  align-items: center;
  gap: 6px;
}

.info_hasApps {
  gap: 16px;
}

.appIcon {
  display: block;
  flex-shrink: 0;
}

.appName,
.appDescription {
  margin: 0;
}

.downloadLinks {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
