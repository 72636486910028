.container {
  width: 100%;
  margin-bottom: 25px;
  position: relative;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  padding: 16px;
  flex-wrap: wrap;
  font-size: 11px;
  color: var(--glz-color-neutral-tone-4);
  background-color: var(--filter-bg);
  box-shadow: 0 2px 2px rgb(var(--glz-color-neutral-tone-5-rgb), 20%);
  border-radius: 0 0 8px 8px;
}

.links {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 12px 24px;
  margin-left: auto;
}

.links_newTab {
  margin-left: 0;
}
