.outer-wrapper_BHzSx {
  position: fixed;
  bottom: 0;
  background-color: rgba(var(--glz-color-neutral-tone-5-rgb), 0.8);
  overflow: auto;
  overscroll-behavior-y: contain;
  filter: saturate(1);
  top: 0 !important;
  right: 0;
  left: 0;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.outer-wrapper_BHzSx.overlay-invisible_sFtVF {
  background-color: transparent;
}
.outer-wrapper_BHzSx::-webkit-scrollbar {
  display: none;
}

.content-layout_OZqM6 {
  position: absolute;
  display: flex;
  min-height: calc(100% + 1px);
  align-items: center;
  justify-content: center;
  top: 0 !important;
  right: 0;
  left: 0;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.content-layout_OZqM6::-webkit-scrollbar {
  display: none;
}

.popup_PWifS {
  position: relative;
  font-family: Verdana, "Dejavu Sans", Geneva, Tahoma, sans-serif;
  box-sizing: border-box;
  border-radius: 6px;
  box-shadow: 0px 8px 24px 0px rgba(var(--glz-color-neutral-tone-5-rgb), 0.16);
  background: var(--glz-color-neutral-tone-0);
  margin: 32px auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
}
.popup_PWifS.size-medium_SiByR {
  max-width: 640px;
}
.popup_PWifS.size-small_VjhYB {
  max-width: 448px;
}

.popup-header_kMJEH {
  padding: 32px 72px 16px 32px;
}

.popup-header-title_e4W-M {
  color: var(--glz-color-neutral-tone-5);
  line-height: 32px;
  letter-spacing: -0.3px;
  font-size: 26px;
  font-weight: normal;
  margin: 0;
}

.back-link-block_CXsr3 {
  width: 100%;
  padding-left: 3px;
  display: flex;
  align-items: center;
  height: 32px;
  margin-bottom: 16px;
}

.back-link_gIPOU svg {
  fill: var(--glz-color-neutral-tone-4);
}
.back-link_gIPOU:hover svg {
  fill: var(--glz-color-primary-tone-1);
}
.back-link_gIPOU:active svg {
  fill: var(--glz-color-primary-tone-2);
}

.close-button_FjMrG {
  position: absolute;
  top: 32px;
  right: 32px;
  background: none;
  border: none;
  padding: 4px;
  cursor: pointer;
  display: flex;
}
.close-button_FjMrG svg {
  fill: var(--glz-color-neutral-tone-3);
}
.close-button_FjMrG:hover svg {
  fill: var(--glz-color-neutral-tone-4);
}

.popup-content_PeeHl {
  padding: 0 32px 16px;
}
.popup-content_PeeHl.theme-transparent_cn9Do {
  background-color: transparent;
}
.popup-content_PeeHl.theme-white_jCi-Q {
  background-color: var(--glz-color-neutral-tone-0);
}
.popup-content_PeeHl.theme-feature_5vZQF {
  background-color: var(--glz-color-warning-tone-4);
}
.popup-content_PeeHl.theme-light_hN9GS {
  background-color: var(--glz-color-warning-tone-3);
}
.popup-content_PeeHl.theme-gray_5j4yh {
  background-color: var(--glz-color-neutral);
}
.popup-content_PeeHl.theme-error_IUJ40 {
  background-color: var(--glz-color-error-tone-3);
  color: var(--glz-color-error);
  padding-top: 4px;
  padding-bottom: 4px;
  text-align: center;
  line-height: 20px;
  font-size: 14px;
  margin-bottom: 0;
  margin-top: 16px;
}
.popup-content_PeeHl.type-form_ec40e {
  padding: 0 8px 16px;
}

.popup-footer_llE8E {
  background-color: var(--glz-color-neutral-tone-0);
  padding: 16px 32px 40px 32px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  display: flex;
  justify-content: flex-start;
  align-content: center;
  flex-direction: row;
}

.outer-wrapper_BHzSx:not(.is-mobile_BR4Ao) .bg-gray_7LnLG {
  background-color: var(--glz-color-neutral);
  padding-top: 32px;
  margin-top: 16px;
}

.popup-content_PeeHl.theme-error_IUJ40 + .popup-footer_llE8E {
  margin-top: 0;
}

.popup-action-wrapper-button_Q3L6c {
  display: inline-flex;
  align-self: center;
}
.popup-action-wrapper-button_Q3L6c:not(:last-child) {
  margin-right: 16px;
}
.popup-action-wrapper-button_Q3L6c.action-second_f6tEi {
  margin-left: auto;
}

.is-mobile_BR4Ao .popup_PWifS {
  display: flex;
  flex-direction: column;
  margin: auto;
  border-radius: 0;
  min-height: 100vh;
  min-height: 100dvh;
}
.is-mobile_BR4Ao .popup-header_kMJEH {
  padding-right: 56px;
  padding-left: 16px;
}
.is-mobile_BR4Ao .close-button_FjMrG {
  right: 16px;
}
.is-mobile_BR4Ao .popup-content_PeeHl {
  padding-left: 16px;
  padding-right: 16px;
}
.is-mobile_BR4Ao .popup-content_PeeHl.type-form_ec40e {
  padding: 0 8px 16px;
}
.is-mobile_BR4Ao .popup-content_PeeHl.theme-error_IUJ40 {
  margin-top: auto;
}
.is-mobile_BR4Ao .popup-footer_llE8E {
  border-radius: 0;
  justify-content: center;
  flex-direction: column;
  margin-top: auto;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 44px;
}
.is-mobile_BR4Ao .popup-content_PeeHl.theme-error_IUJ40 + .popup-footer_llE8E {
  margin-top: 0;
}
.is-mobile_BR4Ao .popup-action-wrapper-button_Q3L6c {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-right: 0;
  margin-left: 0;
  margin-bottom: 16px;
}
.is-mobile_BR4Ao .popup-action-wrapper-button_Q3L6c.action-second_f6tEi {
  margin-left: 0;
}
.is-mobile_BR4Ao .popup-action-wrapper-button_Q3L6c.action-cancel_feHMs {
  order: 10;
  margin-top: 8px;
  margin-bottom: 0;
}