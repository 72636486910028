.container {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
}

@media (max-width: 890px) {
  .container {
    flex-direction: column;
    width: 100%;
    padding: 6px 0;
  }
}

.checkbox.checkbox {
  display: flex;
  align-items: center;
  padding: 6px 16px;
}

@media (max-width: 890px) {
  .checkbox.checkbox {
    width: 100%;
  }
}

.checkbox span {
  margin-bottom: 0;
}

.checkbox_active {
  background-color: var(--filter-bg-dark);
}

.checkboxContent {
  height: 16px;
}

.question {
  margin-top: 2px;
}

@media (min-width: 890px) {
  .mobileDistanceRange {
    display: none;
  }
}
