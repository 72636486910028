.preloader_65856e1d21 {
  transform: rotate(0deg);
  animation: Preloader_65856e1d21 2s linear infinite;
}
@keyframes Preloader_65856e1d21 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.preloader-icon_65856e1d21 {
  display: flex;
}