.item_TRfEs {
  font-family: inherit;
}
.item_TRfEs:first-child {
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
}
.item_TRfEs:last-child {
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
}