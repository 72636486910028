.noContent {
  margin: 90px auto;
}

.heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.totalItems.totalItems.totalItems {
  margin-bottom: 20px;
  margin-top: 0;
}

.actions {
  display: flex;
  align-items: center;
}

.actions_top {
  justify-content: space-between;
  margin-bottom: 8px;
  background-color: var(--glz-color-neutral-tone-1);
  padding: 8px 15px;
}

.actions_bottom {
  justify-content: space-between;
  margin-top: 28px;
}

.sortField {
  flex-direction: row;
  align-items: center;
}

.sortLabel {
  margin-right: 8px;
}

.sortSelect {
  width: 62px;
}

.links {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 12px 24px;
}
