.container {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-right: 8px;
  font-size: 12px;
}

@media (max-width: 890px) {
  .container {
    display: none;
  }
}

.switchIcon {
  --bg-gradient-color: var(--glz-color-neutral-tone-4);

  cursor: pointer;
  display: block;
  width: 18px;
  height: 14px;
  background:
    repeating-linear-gradient(
      to bottom,
      var(--bg-gradient-color),
      var(--bg-gradient-color) 2px,
      transparent 2px,
      transparent 3px,
      var(--bg-gradient-color) 1px,
      var(--bg-gradient-color) 3px,
      transparent 3px
    );
}

.switchIcon_compact {
  --bg-gradient-color: var(--glz-color-neutral-tone-4);

  background:
    repeating-linear-gradient(
      to bottom,
      var(--bg-gradient-color) 0,
      var(--bg-gradient-color) 6px,
      transparent 6px,
      transparent 8px,
      var(--bg-gradient-color) 4px
    );
}

.switchIcon:hover {
  --bg-gradient-color: var(--glz-color-primary-tone-1);
}
