.glz-button-group_mHQKj {
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 32px;
  line-height: normal;
  padding: 0;
}
.glz-button-group_mHQKj .glz-button_9qMY4 {
  outline: 0;
  font-family: Verdana, "Dejavu Sans", Geneva, Tahoma, sans-serif;
  font-size: 12px;
  cursor: pointer;
  color: var(--glz-color-primary);
  background-color: transparent;
  border: 1px solid var(--glz-color-primary);
  transition: background-color 0.2s ease-in;
  text-align: center;
  margin: 0;
  padding: 8px 12px;
  text-transform: none;
  border-radius: 0;
  display: block;
  line-height: unset;
  position: relative;
  height: 32px;
}
.glz-button-group_mHQKj .glz-button_9qMY4:first-child {
  border-radius: 4px 0 0 4px;
}
.glz-button-group_mHQKj .glz-button_9qMY4:last-child {
  border-radius: 0 4px 4px 0;
}
.glz-button-group_mHQKj .glz-button_9qMY4:not(.glz-is-selected_TpHZr):hover {
  background-color: var(--glz-color-primary-tone-3);
}
.glz-button-group_mHQKj .glz-button_9qMY4.glz-is-selected_TpHZr {
  color: var(--glz-color-neutral-tone-0);
  background-color: var(--glz-color-primary);
}
.glz-button-group_mHQKj .glz-button_9qMY4[disabled], .glz-button-group_mHQKj .glz-button_9qMY4[disabled]:hover {
  color: var(--glz-color-neutral-tone-0);
  background-color: var(--glz-color-neutral-tone-3);
  cursor: not-allowed;
  border: 1px solid var(--glz-color-neutral-tone-3);
}
.glz-button-group_mHQKj .glz-button_9qMY4 + .glz-button_9qMY4[disabled], .glz-button-group_mHQKj .glz-button_9qMY4:not([disabled]) + .glz-button_9qMY4 {
  border-left: 0;
}
.glz-button-group_mHQKj .glz-button_9qMY4:focus-visible:before {
  content: "";
  position: absolute;
  top: -4px;
  left: -4px;
  right: -4px;
  bottom: -4px;
  border-radius: 2px;
  border: 1px solid var(--glz-color-primary);
  box-shadow: inset 2px 2px 0 var(--glz-color-neutral-tone-0), inset -2px -2px 0 var(--glz-color-neutral-tone-0);
  z-index: 1;
}
.glz-button-group_mHQKj.glz-is-mobile_ctyB5 {
  width: 100%;
}
.glz-button-group_mHQKj.glz-is-mobile_ctyB5 .glz-select_1GupQ {
  width: 100%;
}