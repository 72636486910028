.mask-wrapper_I2ZA7 {
  position: absolute;
  display: inline-flex;
  left: 8px;
  top: 50%;
  transform: translate(0, -50%);
  pointer-events: none;
}

.invisible-value_RqXf6 {
  white-space: pre;
  user-select: none;
}

.input-wrapper_c7EO8 {
  position: relative;
  display: inline-flex;
  flex-grow: 1;
}
.input-wrapper_c7EO8 input {
  width: 100%;
}

.visible-mask_Fp9-P {
  color: var(--glz-color-neutral-tone-3);
  font-size: inherit;
  white-space: pre;
  user-select: none;
}

.underscore_MdnUS {
  display: inline-flex;
  margin-right: 1px;
  color: var(--glz-color-neutral-tone-3);
}

.underscore_MdnUS:last-child {
  margin-right: 0;
}

.after-icon_Evasb {
  display: inline-flex;
}