.searchStatus {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 20px;
}

@media (max-width: 890px) {
  .searchStatus {
    flex-wrap: wrap;
    align-items: flex-start;
    margin-bottom: 0;
  }
}

.controlsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.actionsContainer {
  display: flex;
  align-items: center;
}

.actionsContainer_top {
  justify-content: flex-end;
  margin-bottom: 8px;
  background-color: var(--glz-color-neutral-tone-1);
  padding: 8px 15px;
}

.actionsContainer_bottom {
  justify-content: space-between;
  margin-top: 28px;
}

@media (max-width: 890px) {
  .actionsContainer_top,
  .actionsContainer_bottom {
    background-color: var(--glz-color-neutral-tone-0);
    padding: 8px;
    flex-wrap: wrap;
    gap: 12px;
  }
}

.pagination {
  margin-left: 32px;
}

@media (max-width: 890px) {
  .pagination {
    margin-left: 0;
    margin-bottom: 20px;
  }
}

.sortField {
  flex-direction: row;
  align-items: center;
}

.sortLabel {
  margin-right: 8px;
  width: 100%;
}

.sortSelect {
  width: 62px;
}

@media (max-width: 530px) {
  .sortSelect {
    width: 100%;
  }
}

@media (max-width: 530px) {
  .sortLabel {
    flex-basis: max-content;
    white-space: nowrap;
  }
}

@media (max-width: 530px) {
  .sortFieldContainer {
    width: 100%;
  }
}

@media (max-width: 530px) {
  .sortFieldContent {
    flex-basis: 100%;
  }
}

.summary {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

@media (max-width: 890px) {
  .summary {
    margin-bottom: 12px;
    margin-right: 8px;
  }
}

.totalItems.totalItems.totalItems {
  margin-bottom: 0;
  margin-top: 0;
}

@media (max-width: 950px) {
  .totalItems.totalItems.totalItems {
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.1px;
  }
}

.hiddenLoads {
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-top: 8px;
  line-height: 16px;
}

.showLoads {
  margin-left: 8px;
}

.subscribeButton {
  margin-right: auto;
}
