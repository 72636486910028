.main {
  display: flex;
  align-items: center;
  gap: 4px;
  flex-wrap: wrap;
}

.distance {
  color: var(--glz-color-neutral-tone-4);
  font-weight: 400;
  white-space: nowrap;
}

.cityName {
  margin: 0;
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  color: var(--glz-color-neutral-tone-5);
}
