.container {
  position: relative;
  width: 100%;
  margin-bottom: 18px;
  background-color: var(--filter-bg);
}

@media (max-width: 890px) {
  .container {
    display: grid;
    grid-auto-rows: auto;
  }
}

.row {
  display: flex;
  padding-left: 4px;
}

.row_darker {
  background-color: var(--filter-bg-dark);
}

.row_psides {
  padding: 0 16px;
}

.row_pb {
  padding-bottom: 20px;
}

.routeParams {
  padding-left: 0;
  padding-bottom: 20px;
}

@media (max-width: 890px) {
  .routeParams {
    padding-bottom: 0;
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 105px) repeat(10, 1fr);
}

.gridColumn {
  grid-column: span 2;
}

.firmsColumn {
  grid-column: span 8;
}

@media (max-width: 1179px) {
  .grid {
    grid-template-columns: repeat(12, 1fr);
  }

  .dimensionsColumn {
    grid-column: span 4;
  }

  .firmsColumn {
    grid-column: span 6;
  }
}

@media (max-width: 890px) {
  .additionalFilters {
    display: none;
  }
}

.header {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.headerRow {
  display: flex;
  justify-content: space-between;
  min-height: 48px;
  align-items: center;
}

.headerRow_border_bottom {
  border-bottom: 1px solid var(--glz-color-neutral-tone-0);
}

@media (max-width: 890px) {
  .desktopDistanceRange {
    display: none;
  }
}

.lastRow {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.notification {
  position: absolute;
  bottom: -12px;
  margin: 0;
  padding: 5px 12px;
  border-radius: 0 0 8px 8px;
  width: 100%;
  color: var(--glz-color-neutral-tone-0);
  text-align: center;
  font-size: 12px;
  background-color: var(--glz-color-success);
  visibility: visible;
  opacity: 1;
  transition: all ease-in-out 0.2s;
}

.notification::after {
  display: block;
  content: '';
  position: absolute;
  right: 270px;
  top: 25px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 0;
  border-color: var(--glz-color-success) transparent transparent;
}

@media (max-width: 890px) {
  .notification {
    position: relative;
    bottom: auto;
  }

  .notification::after {
    display: none;
  }
}

.notification.isHidden {
  position: absolute !important; /* TODO: костыль на время тестирования */
  visibility: hidden;
  transform: translateY(6px);
  opacity: 0;
}

.geoRow {
  gap: 24px;
}

@media (max-width: 1179px) {
  .geoRow {
    gap: 12px;
  }
}

@media (max-width: 890px) {
  .geoRow {
    grid-row-start: 1;
    flex-direction: column;
    padding-bottom: 0;
  }
}

.loadParams {
  display: flex;
  flex-shrink: 0;
  gap: 12px;
}

@media (max-width: 1179px) {
  .loadParams {
    gap: 4px;
  }
}

@media (max-width: 890px) {
  .loadParams {
    gap: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid var(--glz-color-neutral-tone-2);
  }
}
