.desktopOptionList.desktopOptionList {
  height: 432px;
  padding-left: 0;
  padding-top: 0;
}

.desktopOptionList.desktopOptionList label {
  margin-bottom: 8px;
}

.desktopWrapper.desktopWrapper {
  border: none;
}
