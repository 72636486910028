.container {
  padding: 20px;
  border-radius: 4px;
  background-color: var(--glz-color-neutral-tone-0);
}

.link {
  margin-top: 8px;
}

.isBold {
  font-weight: 700;
}
