.additionalAction {
  border: solid 1px var(--glz-color-neutral-tone-4);
  border-top-color: var(--glz-color-neutral-tone-3);
  border-radius: 2px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  width: 100%;
  display: flex;
  align-items: center;
  box-shadow: 0 1px 2px 0 rgb(var(--glz-color-neutral-tone-5-rgb), 21%);
  background-color: var(--glz-color-neutral-tone-0);
}
