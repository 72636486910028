.container {
  display: flex;
  flex-direction: column;
}

.optionName {
  max-width: 100%;
  white-space: break-spaces;
}

.listItem:first-child .optionName {
  margin-top: 0;
}

@media (max-width: 890px) {
  .listItem {
    margin-bottom: 16px;
  }
}
