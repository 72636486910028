.date-picker_3h2Ab {
  width: 152px;
  position: relative;
}
.date-picker_3h2Ab .mask-wrapper_2rv62 {
  margin-left: -2px;
}
.date-picker_3h2Ab .input_H9v8X {
  letter-spacing: -0.2px;
  width: 100%;
}
.date-picker_3h2Ab .input_H9v8X input {
  padding-left: 4px;
}
.date-picker_3h2Ab .input-for-native_2u05x {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  border: none;
  outline: 0;
  opacity: 0;
  padding: 0;
}

.clear-button_OwRxv {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: none;
  cursor: pointer;
}
.clear-button_OwRxv .clear-icon_2vJHM {
  fill: var(--glz-color-neutral-tone-3);
}
.clear-button_OwRxv:focus-visible .clear-icon_2vJHM, .clear-button_OwRxv:hover .clear-icon_2vJHM {
  fill: var(--glz-color-neutral-tone-4);
}
.clear-button_OwRxv:active .clear-icon_2vJHM {
  fill: var(--glz-color-neutral-tone-5);
}
.clear-button_OwRxv:focus {
  outline: 0;
}