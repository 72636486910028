.container {
  display: flex;
  flex-direction: column;
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
}

.topPart {
  background-color: var(--glz-color-neutral-tone-0);
}

@media (max-width: 890px) {
  .topPart,
  .bottomPart {
    padding-right: var(--body-paddings);
    padding-left: var(--body-paddings);
  }
}

.row {
  display: flex;
}

.bottomRow {
  margin-bottom: 16px;
  justify-content: space-between;
}

@media (max-width: 890px) {
  .bottomRow {
    flex-direction: column;
  }
}

.controlsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap-reverse;
  gap: 12px 32px;
}

@media (max-width: 890px) {
  .controlsContainer {
    flex-direction: column-reverse;
  }
}

.error {
  display: flex;
  justify-content: flex-end;
  color: var(--glz-color-error);
  font-size: 14px;
}

@media (max-width: 890px) {
  .error {
    justify-content: center;
  }
}

.banner {
  margin-top: 38px;
  margin-bottom: 38px;
}

@media (max-width: 890px) {
  .banner {
    display: flex;
    justify-content: center;
  }
}

.banner_hidden {
  height: 0;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  margin: 0;
}

.tab {
  box-shadow: 0 2px 2px rgb(var(--glz-color-neutral-tone-5-rgb), 20%);
  border-radius: 0 0 8px 8px;
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 32px 0;
}

.h1 {
  margin: 0;
}

@media (max-width: 890px) {
  .titleContainer {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .h1.h1 {
    font-size: 20px;
    line-height: 24px;
  }
}
