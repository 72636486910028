.preloader {
  display: grid;
  grid-template-columns: 16px 96px 1fr 1fr 1fr 1fr 240px;
  grid-gap: 12px 8px;
  padding: 8px;
}

.preloader:nth-child(odd) {
  background-color: var(--glz-color-neutral-tone-1);
}

.preloader :nth-child(8) {
  grid-column: 1 / 7;
}

.preloader :nth-child(9) {
  grid-column-start: 7;
}

@media (max-width: 890px) {
  .preloader {
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    margin-bottom: 4px;
    padding: 12px 8px 8px;
    background-color: var(--glz-color-neutral-tone-0);
    box-shadow: 0 1px 2px rgba(var(--glz-color-neutral-tone-5-rgb), 0.2);
  }
}
