.columnButton {
  max-width: 173px;
  margin-right: auto;
  padding-right: 12px;
}

@media (max-width: 1179px) {
  .columnButton {
    max-width: 32.5%;
  }

  .separator {
    left: 33%;
    width: calc(100% - 129px);
  }
}
