.ADR {
  margin-top: 8px;
  margin-left: 18px;
}

.inputWrapper {
  font-size: 12px;
  line-height: 16px;
  justify-content: space-between;
}

.suggestion {
  font-size: 12px;
  line-height: 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.suggestionDescription {
  margin-left: 4px;
  color: var(--glz-color-neutral-tone-4);
}
