.suggestion-header_Ee1Un {
  font-size: 12px;
  color: var(--glz-color-neutral-tone-5);
  letter-spacing: -0.1px;
}

.suggestion-matched-fields_7C7jq {
  font-size: 12px;
  letter-spacing: -0.1px;
  color: rgba(var(--glz-color-neutral-tone-5-rgb), 0.6);
}

.match_7p03J {
  text-decoration: underline;
}