.pagination_Nze-D {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: Verdana, "Dejavu Sans", Geneva, Tahoma, sans-serif;
}

.input_mEUzW {
  max-width: 48px;
  margin: 0 2px;
}

.previous_HKajO,
.next_FJXnH {
  cursor: pointer;
  display: flex;
  align-items: center;
  user-select: none;
  background: none;
  border: none;
  padding: 5px;
}
.previous_HKajO svg,
.next_FJXnH svg {
  fill: var(--glz-color-neutral-tone-4);
}
.previous_HKajO:hover svg, .previous_HKajO:focus-visible svg,
.next_FJXnH:hover svg,
.next_FJXnH:focus-visible svg {
  fill: var(--glz-color-neutral-tone-4);
}
.previous_HKajO.hide_ilkOM,
.next_FJXnH.hide_ilkOM {
  display: none;
}
.previous_HKajO:focus-visible,
.next_FJXnH:focus-visible {
  border-radius: 0.1px;
  outline: 1px solid var(--glz-color-primary);
  outline-offset: 2px;
  padding-top: 5px;
}

.previous_HKajO {
  margin-right: 2px;
}

.next_FJXnH {
  margin-left: 2px;
}

.item_JqfSO {
  display: inline;
  padding: 4px 8px;
  cursor: pointer;
  color: var(--glz-color-primary);
  user-select: none;
  background: none;
  border: none;
  font-size: 14px;
  font-family: Verdana, "Dejavu Sans", Geneva, Tahoma, sans-serif;
  margin: 0 1px;
}
.item_JqfSO:hover, .item_JqfSO:focus-visible {
  color: var(--glz-color-primary-tone-1);
}
.item_JqfSO.active_WLE-D {
  color: var(--glz-color-neutral-tone-5);
  border-radius: 2px;
  background-color: var(--glz-color-neutral-tone-2);
}
.item_JqfSO:focus-visible {
  outline: 1px solid var(--glz-color-primary);
  outline-offset: 2px;
}
.item_JqfSO:focus-visible {
  border-radius: 0.1px;
  outline: 1px solid var(--glz-color-primary);
  outline-offset: 2px;
  padding-top: 5px;
}

.ellipsis_HKqns {
  color: var(--glz-color-neutral-tone-4);
  font-size: 14px;
  font-family: Verdana, "Dejavu Sans", Geneva, Tahoma, sans-serif;
  line-height: 20px;
  padding: 0 4px;
  margin: 0 2px;
}

.total_5B9k1 {
  margin: 0 2px;
  user-select: none;
  color: var(--glz-color-neutral-tone-4);
}
.total_5B9k1 .total-index_kjYkG {
  cursor: pointer;
  background: none;
  border: none;
  color: var(--glz-color-neutral-tone-4);
  font-size: 14px;
  font-family: Verdana, "Dejavu Sans", Geneva, Tahoma, sans-serif;
  padding: 4px;
}
.total_5B9k1 .total-index_kjYkG:hover, .total_5B9k1 .total-index_kjYkG:focus-visible {
  color: var(--glz-color-primary-tone-1);
}
.total_5B9k1 .total-index_kjYkG:focus-visible {
  border-radius: 0.1px;
  outline: 1px solid var(--glz-color-primary);
  outline-offset: 2px;
  padding-top: 5px;
}

.tooltips_ckuCF {
  display: block;
}
@media (max-width: 879px) {
  .tooltips_ckuCF {
    display: none;
  }
}