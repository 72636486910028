.board {
  display: inline-flex;
  align-items: center;
  padding: 2px 4px;
  max-width: 100%;
  font-size: 12px;
  line-height: 20px;
  border-radius: 2px;
}

@media (max-width: 890px) {
  .board {
    max-width: 280px;
  }
}

.inner {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.board_white {
  color: var(--glz-color-neutral-tone-0);
}
