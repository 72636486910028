.route {
  display: flex;
  width: 100%;
}

@media (max-width: 890px) {
  .route {
    flex-direction: column;
    padding-top: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid var(--glz-color-neutral-tone-2);
  }
}

.geoWrapper {
  width: 100%;
}

@media (max-width: 890px) {
  .geoWrapperFrom {
    padding-bottom: 12px;
    border-bottom: 1px solid var(--glz-color-neutral-tone-2);
  }

  .geoWrapperTo {
    padding-top: 12px;
  }
}

.swap {
  display: flex;
  margin: 28px 10px 0;
}

.swap svg {
  width: 24px;
  height: 32px;
  fill: var(--glz-color-primary);
  transition: all 0.2s ease-in-out;
}

.swap svg:hover {
  cursor: pointer;
  fill: var(--glz-color-primary-tone-1);
}

.swap svg:active {
  fill: var(--glz-color-primary-tone-2);
}

@media (max-width: 1179px) {
  .swap {
    margin-left: 2px;
    margin-right: 2px;
  }
}

@media (max-width: 890px) {
  .swapWrapper {
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .swap {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    margin: 0;
    border-radius: 24px;
    border: 1px solid var(--glz-color-neutral-tone-2);
    background: var(--glz-color-neutral-tone-1);
    transform: rotate(90deg);
  }
}

.truckInfo {
  display: flex;
  margin-top: 12px;
}
