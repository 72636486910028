.container {
  display: flex;
  margin-right: 2px;
}

@media (max-width: 890px) {
  .container,
  .dropdownButton,
  .button {
    width: 100%;
  }
}

.dropdown.dropdown {
  right: 0;
  left: unset;
}
