.fieldContainer {
  margin-left: auto;
}

@media (max-width: 890px) {
  .fieldContainer {
    margin-left: 0;
    margin-bottom: 12px;
  }
}

@media (max-width: 530px) {
  .fieldContainer,
  .fieldContent {
    flex-basis: 100%;
  }
}

@media (max-width: 530px) {
  .fieldLabel {
    flex-basis: max-content;
    white-space: nowrap;
  }
}
