.container {
  width: 360px;
  height: 100%;
  min-width: 360px;
  min-height: 920px;
  padding-top: 60px;
  padding-left: 30px;
  background-color: var(--lighter-gray);
}

.media {
  position: sticky;
  top: 60px;
}
