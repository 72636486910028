.container {
  display: inline-flex;
  flex-direction: column;
  border-right: 1px solid var(--glz-color-neutral-tone-0);
  padding-left: 12px;
  padding-right: 12px;
  width: 100%;
  max-width: 100%;
  position: relative;
}

.container:last-child {
  border: none;
}

.headerContainer {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  min-height: 56px;
}

.header {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  min-height: 56px;
  width: 100%;
}

.content {
  padding-bottom: 30px;
}

.button {
  font-size: 12px;
  line-height: 16px;
}

.buttonText {
  margin-right: 6px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
