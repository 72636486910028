.stack {
  display: flex;
  flex-direction: row;
}

.stack > * {
  margin-right: var(--h-stack-gap);
}

.stack > :last-child {
  margin-right: 0;
}
