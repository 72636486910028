.stack {
  display: flex;
  flex-direction: column;
}

.stack > * {
  margin-bottom: var(--stack-gap);
}

.stack > :last-child {
  margin-bottom: 0;
}
