@charset "UTF-8";
.calendar_gpRjr {
  position: relative;
  width: 280px;
  height: 296px;
  border: 1px solid var(--glz-color-neutral-tone-4);
  background-color: var(--glz-color-neutral-tone-0);
  padding: 16px 16px 0 16px;
  box-sizing: border-box;
  border-radius: 2px;
  box-shadow: 0px 1px 2px 0px rgba(var(--glz-color-neutral-tone-5-rgb), 0.2);
  font-family: Verdana, "Dejavu Sans", Geneva, Tahoma, sans-serif;
}

.header_diW-0 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Verdana, "Dejavu Sans", Geneva, Tahoma, sans-serif;
  height: 32px;
}
.header_diW-0 .month-and-year_2GCVF {
  display: inline-flex;
  align-items: center;
}
.header_diW-0 .current-month_jPYL- {
  font-size: 16px;
  text-transform: capitalize;
  line-height: 24px;
  color: var(--glz-color-neutral-tone-5);
}
.header_diW-0 .current-year_RRRBe {
  display: flex;
  align-items: center;
  padding-left: 4px;
  font-family: Verdana, "Dejavu Sans", Geneva, Tahoma, sans-serif;
  font-size: 16px;
  line-height: 24px;
  border: none;
  background: transparent;
  color: var(--glz-color-neutral-tone-5);
}
.header_diW-0 .current-year_RRRBe.selector-visible_MkSua {
  color: var(--glz-color-primary);
  cursor: pointer;
}
.header_diW-0 .current-year_RRRBe.selector-visible_MkSua .triangle-icon_ENuLn {
  fill: var(--glz-color-primary);
}
.header_diW-0 .current-year_RRRBe.selector-visible_MkSua .triangle-icon_ENuLn.arrow-up_Xho8X {
  transform: rotate(180deg);
}
.header_diW-0 .current-year_RRRBe.selector-visible_MkSua:hover, .header_diW-0 .current-year_RRRBe.selector-visible_MkSua:focus-visible {
  color: var(--glz-color-primary-tone-1);
}
.header_diW-0 .current-year_RRRBe.selector-visible_MkSua:hover .triangle-icon_ENuLn, .header_diW-0 .current-year_RRRBe.selector-visible_MkSua:focus-visible .triangle-icon_ENuLn {
  fill: var(--glz-color-primary-tone-1);
}
.header_diW-0 .current-year_RRRBe.selector-visible_MkSua:active {
  color: var(--glz-color-primary-tone-2);
}
.header_diW-0 .current-year_RRRBe.selector-visible_MkSua:active .triangle-icon_ENuLn {
  fill: var(--glz-color-primary-tone-2);
}
.header_diW-0 .current-year_RRRBe.selector-visible_MkSua:focus {
  outline: 0;
}
.header_diW-0 .current-year-text_nbZHx {
  margin-right: 4px;
}

.navigation-buttons_owsDr {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.navigation-buttons_owsDr .navigation-arrow_3oSZJ {
  display: inline-flex;
  box-sizing: border-box;
  border: none;
  background: none;
  padding: 4px;
  cursor: pointer;
  border-radius: 4px;
}
.navigation-buttons_owsDr .navigation-arrow_3oSZJ:first-child {
  margin-right: 4px;
}
.navigation-buttons_owsDr .navigation-arrow_3oSZJ .arrow-icon_BvBJD {
  fill: var(--glz-color-neutral-tone-5);
}
.navigation-buttons_owsDr .navigation-arrow_3oSZJ:not([disabled]):hover, .navigation-buttons_owsDr .navigation-arrow_3oSZJ:not([disabled]):focus-visible {
  background-color: var(--glz-color-primary-tone-3);
}
.navigation-buttons_owsDr .navigation-arrow_3oSZJ:not([disabled]):hover .arrow-icon_BvBJD, .navigation-buttons_owsDr .navigation-arrow_3oSZJ:not([disabled]):focus-visible .arrow-icon_BvBJD {
  fill: var(--glz-color-primary-tone-1);
}
.navigation-buttons_owsDr .navigation-arrow_3oSZJ:not([disabled]):active {
  background-color: var(--glz-color-primary-tone-4);
}
.navigation-buttons_owsDr .navigation-arrow_3oSZJ:not([disabled]):active .arrow-icon_BvBJD {
  fill: var(--glz-color-primary-tone-2);
}
.navigation-buttons_owsDr .navigation-arrow_3oSZJ:focus {
  outline: 0;
}
.navigation-buttons_owsDr .navigation-arrow_3oSZJ[disabled] {
  cursor: not-allowed;
}
.navigation-buttons_owsDr .navigation-arrow_3oSZJ[disabled] .arrow-icon_BvBJD {
  fill: var(--glz-color-neutral-tone-3);
}

.weekdays_30GI6 {
  height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
}
.weekdays_30GI6 .weekday_kkOWo {
  display: inline-flex;
  justify-content: center;
  width: 32px;
  color: var(--glz-color-neutral-tone-5);
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
}

.days_y2BqB {
  display: grid;
  grid-gap: 0 4px;
  grid-template-columns: repeat(7, 1fr);
  justify-items: center;
}
.days_y2BqB .day-button_z3Ui4 {
  width: 32px;
  height: 32px;
  padding: 0;
  font-family: Verdana, "Dejavu Sans", Geneva, Tahoma, sans-serif;
  font-size: 14px;
  color: var(--glz-color-neutral-tone-5);
  text-align: center;
  background: none;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.1s ease-in-out;
}
.days_y2BqB .day-button_z3Ui4.current-day_xxt5K {
  font-weight: 700;
}
.days_y2BqB .day-button_z3Ui4.unactive_zlIfn {
  color: var(--glz-color-neutral-tone-4);
}
.days_y2BqB .day-button_z3Ui4.selected_G-ZKt {
  color: var(--glz-color-neutral-tone-0);
  background-color: var(--glz-color-primary);
}
.days_y2BqB .day-button_z3Ui4[disabled] {
  color: var(--glz-color-neutral-tone-3);
  cursor: not-allowed;
}
.days_y2BqB .day-button_z3Ui4:not([disabled]):not(.selected_G-ZKt):hover, .days_y2BqB .day-button_z3Ui4:not([disabled]):not(.selected_G-ZKt):focus-visible {
  background-color: var(--glz-color-primary-tone-3);
}
.days_y2BqB .day-button_z3Ui4:not([disabled]):not(.selected_G-ZKt):active {
  background-color: var(--glz-color-primary-tone-4);
}
.days_y2BqB .day-button_z3Ui4:focus {
  outline: 0;
}

.years-container_ZaBVI {
  position: relative;
  height: 235px;
  width: 100%;
  background-color: var(--glz-color-neutral-tone-0);
  margin-top: 8px;
  width: 248px;
  box-sizing: border-box;
}
.years-container_ZaBVI .years_NMFXM {
  display: grid;
  grid-template-columns: repeat(4, 60px);
  align-items: flex-start;
  justify-content: space-between;
  column-gap: 4px;
  width: 248px;
  box-sizing: border-box;
}
.years-container_ZaBVI .years_NMFXM .years-button_z0HCl {
  width: 56px;
  height: 32px;
  font-family: Verdana, "Dejavu Sans", Geneva, Tahoma, sans-serif;
  line-height: 16px;
  font-size: 14px;
  color: var(--glz-color-neutral-tone-5);
  border: none;
  border-radius: 4px;
  background: none;
  cursor: pointer;
}
.years-container_ZaBVI .years_NMFXM .years-button_z0HCl.current-year_RRRBe {
  font-weight: 700;
}
.years-container_ZaBVI .years_NMFXM .years-button_z0HCl.selected_G-ZKt {
  color: var(--glz-color-neutral-tone-0);
  background-color: var(--glz-color-primary);
}
.years-container_ZaBVI .years_NMFXM .years-button_z0HCl:not(.selected_G-ZKt):hover, .years-container_ZaBVI .years_NMFXM .years-button_z0HCl:not(.selected_G-ZKt):focus-visible {
  background-color: var(--glz-color-primary-tone-3);
}
.years-container_ZaBVI .years_NMFXM .years-button_z0HCl:not(.selected_G-ZKt):active {
  background-color: var(--glz-color-primary-tone-4);
}
.years-container_ZaBVI .years_NMFXM .years-button_z0HCl:focus {
  outline: 0;
}

.fog-container_swPya {
  position: relative;
  max-width: 100%;
  height: 100%;
}
.fog-container_swPya .scrollable-container_1HEPI {
  height: 100%;
  width: 100%;
  overflow-y: auto;
}
.fog-container_swPya .foggy-top_h-iL6,
.fog-container_swPya .foggy-bottom_P5QVE {
  position: absolute;
  left: 0;
  right: 0;
  height: 32px; /* Высота тумана */
  pointer-events: none; /* Чтобы не блокировать прокрутку */
  z-index: 1;
  opacity: 0;
  transition: opacity 0.01s ease-in-out;
}
.fog-container_swPya .trigger_JXFMZ {
  visibility: hidden;
  width: 10px;
}
.fog-container_swPya .visible_YLpnR {
  opacity: 1;
}

.foggy-top_h-iL6 {
  top: 0;
  background: linear-gradient(to bottom, var(--glz-color-neutral-tone-0) 0%, rgba(255, 255, 255, 0.6) 52.98%, rgba(255, 255, 255, 0) 80%);
}

.foggy-bottom_P5QVE {
  bottom: 0;
  background: linear-gradient(to top, var(--glz-color-neutral-tone-0) 0%, rgba(255, 255, 255, 0.6) 52.98%, rgba(255, 255, 255, 0) 80%);
}

[data-theme=dark-theme] .foggy-top_h-iL6 {
  top: 0;
  background: linear-gradient(to bottom, var(--glz-color-neutral-tone-0) 0%, rgba(7, 24, 41, 0) 52.98%);
}
[data-theme=dark-theme] .foggy-bottom_P5QVE {
  bottom: 0;
  background: linear-gradient(to top, var(--glz-color-neutral-tone-0) 0%, rgba(7, 24, 41, 0) 52.98%);
}