.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 400px;
}

.icon {
  margin-bottom: 12px;
}

.title.title.title {
  color: var(--glz-color-neutral-tone-4);
  margin-bottom: 9px;
  text-align: center;
  text-wrap: balance;
}

.desc {
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: var(--glz-color-neutral-tone-4);
  margin-bottom: 9px;
}
