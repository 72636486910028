.label > span {
  margin-bottom: 8px;
}

.label:last-child > span {
  margin-bottom: 0;
}

.content.content {
  display: flex;
  align-items: center;
  max-width: 100%;
}

.label.label.label.label input + span::before {
  top: 50%;
  transform: translateY(-50%);
}

.label.label.label.label input + span::after {
  top: 50%;
  transform: translateY(-50%);
}
