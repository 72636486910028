.container {
  display: flex;
  align-items: center;
  margin-right: 60px;
  flex-wrap: wrap;
  gap: 12px;
}

@media (max-width: 890px) {
  .container {
    margin-right: 0;
    margin-bottom: 28px;
  }
}

.linkContainer {
  display: flex;
  align-items: center;
}

.icon {
  margin-right: 5px;
}

@media (max-width: 1179px) {
  .instructionSecondPart {
    display: none;
  }
}

@media (max-width: 890px) {
  .instructionSecondPart {
    display: initial;
  }
}
