.button {
  background: var(--filter-bg-dark);
  width: 100%;
  display: none;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0 0 8px 8px;
}

@media (max-width: 890px) {
  .button {
    display: flex;
  }
}
