.filterNameField {
  margin-bottom: 20px;
}

.filterNameLabel {
  height: auto;
  overflow: initial;
  white-space: normal;
}

.field_fullWidth {
  max-width: none;
}

.preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  padding: 32px;
}

.footer.footer.footer {
  margin-top: 0;
}

.subscribeContainer {
  box-shadow: 0 1px 0 0 rgba(89, 110, 129, 20%) inset;
  background-color: var(--glz-color-success-tone-3);
}

.subscribeContent.subscribeContent {
  position: relative;
  padding: 20px 32px;
}

.subscribeIcon {
  position: absolute;
  top: 34px;
  right: 34px;
}

@media (max-width: 890px) {
  .subscribeIcon {
    top: 16px;
    right: 16px;
  }
}
