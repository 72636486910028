.container_bItmg {
  display: block;
  position: relative;
  font-family: Verdana, "Dejavu Sans", Geneva, Tahoma, sans-serif;
}

.label_om4-h {
  display: flex;
  align-items: center;
  padding: 0 8px;
  position: relative;
  border: 1px solid var(--glz-color-neutral-tone-3);
  background: var(--glz-color-neutral-tone-0);
}
.label_om4-h.focused_ZSOl- {
  border: 1px solid var(--glz-color-neutral-tone-4);
  box-shadow: 0 1px 2px rgba(var(--glz-color-neutral-tone-5-rgb), 0.21);
}
.label_om4-h.gradient_Mzhdc {
  position: absolute;
  right: 0;
  top: 1px;
  bottom: 1px;
  background-image: linear-gradient(90deg, rgba(var(--glz-color-neutral-tone-0-rgb), 0.0001), var(--glz-color-neutral-tone-0));
  width: 30px;
}
.label_om4-h.disabled_hJh-A {
  background-color: var(--glz-color-neutral);
  border: 1px solid var(--glz-color-neutral-tone-3);
  cursor: not-allowed;
}
.label_om4-h.disabled_hJh-A .input_e6J3H {
  -webkit-text-fill-color: var(--glz-color-neutral-tone-3);
  color: var(--glz-color-neutral-tone-3);
  cursor: not-allowed;
}
.label_om4-h.error_nzSwG {
  background: var(--glz-color-error-tone-3);
  border-color: var(--glz-color-error);
}
.label_om4-h.error_nzSwG.gradient_Mzhdc {
  background-image: linear-gradient(90deg, rgba(var(--glz-color-neutral-tone-0-rgb), 0.0001), var(--glz-color-error-tone-3));
}
.label_om4-h .gradient-pad_1E6nn {
  right: 30px;
}

.input-wrapper_JaqEh {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.input-wrapper_JaqEh input {
  width: 100%;
  padding-left: 0;
}

.suggestionsContainer_rzfYj {
  display: none;
  position: absolute;
  top: 100%;
  width: 100%;
  margin-top: -1px;
  background: var(--glz-color-neutral-tone-0);
  border: 1px solid var(--glz-color-neutral-tone-4);
  z-index: 99;
  overflow: hidden;
  box-sizing: border-box;
}

.suggestionsContainerOpen_bLr0C {
  display: block;
  max-height: 200px;
  overflow: auto;
}

.before_Nmybo {
  line-height: 10px;
  padding-right: 8px;
}

.tooltip_d7NlG {
  display: flex;
}

.after-icon_d8c5r {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
}

.clear-after-icon_2B0ED {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  cursor: pointer;
  user-select: none;
  border: 0;
  background-color: transparent;
  transition: fill 0.3s;
}
.clear-after-icon_2B0ED svg {
  fill: var(--glz-color-neutral-tone-3);
}
.clear-after-icon_2B0ED:hover svg {
  fill: var(--glz-color-neutral-tone-5);
}

.input_e6J3H {
  caret-color: var(--glz-color-neutral-tone-3);
  width: 100%;
  background: none;
  border: 0;
  padding: 5px 0 5px 0;
  font-size: 12px;
  font-weight: normal;
  color: var(--glz-color-neutral-tone-5);
  position: relative;
  height: 32px;
  outline: 0;
  font-family: Verdana, "Dejavu Sans", Geneva, Tahoma, sans-serif;
  box-sizing: border-box;
}
.input_e6J3H::placeholder {
  color: var(--glz-color-neutral-tone-3);
}
.input_e6J3H:focus {
  outline: 0;
}

.placeholder_3tmWy {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  font-size: 12px;
  font-weight: normal;
  color: var(--glz-color-neutral-tone-3);
  white-space: nowrap;
  height: 30px;
  line-height: 15px;
  padding: 8px 0;
  box-sizing: border-box;
}

.suggestionsList_K6NpE {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  overflow-y: auto;
  max-height: 100%;
  position: relative;
}

.suggestion_wldEA {
  display: block;
  list-style: none;
  margin: 0;
  padding: 8px;
  font-size: 12px;
  line-height: 18px;
  color: var(--glz-color-neutral-tone-5);
  cursor: pointer;
}

.suggestionHighlighted_rNcjh {
  background: var(--glz-color-primary-tone-3);
}

.match_0ifj5 {
  font-weight: bold;
  color: rgba(var(--glz-color-neutral-tone-5-rgb), 0.8);
  text-decoration: underline;
}