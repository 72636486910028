.subtext_12e7ed52e0 {
  display: flex;
  gap: 5px;
}

.glz-is-error_12e7ed52e0 svg {
  fill: var(--glz-color-error);
}

.glz-is-warning_12e7ed52e0 svg {
  fill: var(--glz-color-warning);
}

.glz-is-default_12e7ed52e0 svg {
  fill: var(--glz-color-neutral-tone-4);
}