.tooltip_fb61e36a44 {
  padding: 8px;
  font-family: Verdana, "Dejavu Sans", Geneva, Tahoma, sans-serif;
  color: var(--glz-color-neutral-tone-0);
  border-radius: 2px;
  font-size: 11px;
  padding: 8px;
  line-height: 12px;
  letter-spacing: -0.3px;
  text-align: center;
}