.status {
  margin-left: 4px;
  margin-top: 3px;
}

.status_updated {
  fill: var(--glz-color-warning);
}

.status_new {
  fill: var(--glz-color-success);
}

.status_error {
  fill: var(--glz-color-error);
}
