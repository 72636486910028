.container {
  margin-top: 10px;
  display: grid;
  grid-template-columns: max-content 1fr;
  column-gap: 10px;
  row-gap: 12px;
}

@media (max-width: 1060px) and (min-width: 890px) {
  .container {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 890px) {
  .container {
    margin-top: 16px;
  }
}

.label {
  margin-top: 6px;
  font-size: 12px;
}

.label::first-letter {
  text-transform: capitalize;
}

.datepickerInput {
  width: 100%;
}

label.datepickerInput > div {
  overflow: hidden;
}

@media (max-width: 890px) {
  .datepickerInput {
    max-width: 152px;
  }
}

.button.button {
  display: block;
  text-align: left;
}
