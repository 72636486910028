.listItem {
  padding: 5px 2px 2px 8px;
}

.list {
  width: 260px;
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

@media (max-width: 890px) {
  .list {
    width: 288px;
  }
}

.list .listItem:first-child {
  padding-top: 10px;
}

.list .listItem:last-child {
  padding-bottom: 10px;
}

.optionLabel {
  cursor: pointer;
  display: flex;
  width: 100%;
}

.description {
  display: flex;
  flex-direction: column;
  font-size: 11px;
  margin-left: 5px;
  line-height: 1.2;
}

.description span {
  font-style: 11px;
  opacity: 0.6;
  line-height: 1.7;
}
