.container {
  display: flex;
  justify-content: space-between;
  overflow-x: auto;
  scrollbar-width: none;
}

.container::-webkit-scrollbar {
  display: none;
}

.mainTabs {
  display: flex;
}

.counter {
  margin-left: 4px;
}
