.container {
  position: relative;
  background-color: var(--filter-bg);
  width: 100%;
  margin-bottom: 18px;
  padding: 20px;
  min-height: 363px;
}

@media (max-width: 890px) {
  .container {
    padding: 20px 12px;
  }
}

.content {
  position: relative;
  display: flex;
  gap: 4px;
  min-height: 244px;
  margin-bottom: 12px;
}

.noContent {
  min-height: 244px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 1px;
  width: 100%;
}

.preloader {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--glz-color-neutral-tone-0);
  opacity: 0.55;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bottomContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}

@media (max-width: 890px) {
  .bottomContainer {
    flex-direction: column;
  }
}

.itemsCount {
  font-size: 14px;
  color: var(--glz-color-neutral-tone-4);
}

@media (max-width: 890px) {
  .itemsCount {
    font-size: 12px;
  }
}

.pagination {
  white-space: nowrap;
}
