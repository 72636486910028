.dropdown-firms_rgHtT {
  min-width: 260px;
  max-width: 460px;
  overflow: hidden !important;
}
.dropdown-firms_rgHtT.restricted_20rex {
  max-height: 251px;
}
@media screen and (max-width: 460px) {
  .dropdown-firms_rgHtT {
    max-width: 100vw;
  }
}
.dropdown-firms_rgHtT.empty_PGYnG {
  display: block;
  margin-top: -1px;
  min-height: 0;
}
.dropdown-firms_rgHtT.empty_PGYnG .firms-search-footer_vWYY3 {
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: -0.1px;
  color: var(--glz-color-neutral-tone-5);
  border-top: 0;
}
.dropdown-firms_rgHtT .suggestions-container-long_6D0xU {
  height: 200px;
}
.dropdown-firms_rgHtT .suggestions-container_DjKsj {
  height: auto;
}
.dropdown-firms_rgHtT .firms-search-footer_vWYY3 {
  border-top: solid 1px var(--glz-color-neutral-tone-4);
  width: 100%;
  padding-top: 13px;
  padding-left: 9px;
  padding-bottom: 13px;
}
.dropdown-firms_rgHtT .firms-search-footer_vWYY3 .extended-search-link_sdGAO {
  font-size: 12px;
}
.dropdown-firms_rgHtT .match_RGasN {
  text-decoration: underline;
}