.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 6px;
  margin-bottom: 10px;
  min-height: 20px;
  font-size: 11px;
  color: var(--glz-color-neutral-tone-4);
}
