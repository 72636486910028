.container {
  display: flex;
  justify-content: space-between;
  margin-top: 35px;
}

@media (max-width: 890px) {
  .container {
    justify-content: center;
  }
}
