.selectorContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.toggleIcon_active {
  transform: rotate(180deg);
}

.selector {
  margin-top: 4px;
  width: 280px;
  height: 298px;
}

.buttons {
  margin-top: 5px;
  display: flex;
  align-items: center;
}

.toggleButton {
  display: flex;
  align-items: center;
  max-width: 100%;
}

.toggleButtonIcon {
  margin-top: 3px;
}

.triggerWrapper {
  max-width: 100%;
}

.buttonText {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
