.field {
  column-gap: 8px;
  margin-bottom: 10px;
}

@media (max-width: 890px) {
  .field {
    max-width: 288px;
    justify-content: space-between;
    margin-bottom: 16px;
  }
}

.fieldContent {
  flex-basis: 122px;
}

@media (max-width: 890px) {
  .fieldContent {
    flex-basis: 152px;
  }
}

.fieldWide {
  width: 100%;
  flex-grow: 1;
}

@media (max-width: 890px) {
  .fieldWide {
    max-width: 152px;
  }
}

.fieldLabel {
  flex-basis: 52px;
  max-width: 52px;
}

.withoutDimensions {
  margin-bottom: 2px;
  display: inline-block;
}

@media (max-width: 890px) {
  .withoutDimensions {
    margin-bottom: 16px;
  }
}

.palletsInputWrapper {
  width: 100%;
}
