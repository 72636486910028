.container {
  display: flex;
  align-items: center;
  padding: 16px;
  background-color: var(--filter-bg-dark);
  gap: 16px 32px;
}

@media (max-width: 890px) {
  .container {
    flex-direction: column;
    align-items: flex-start;
  }
}

.distanceInputContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 890px) {
  .distanceInputContainer {
    width: 100%;
  }
}

@media (max-width: 890px) {
  .ellipseInputContainer {
    max-width: 315px;
  }
}

.lengthInputContainer {
  max-width: 200px;
}

.label {
  max-width: 400px;
  white-space: pre;
  margin-right: 8px;
}

.desc {
  margin: 0;
}

.ellipseInput.ellipseInput {
  width: 56px;
}

.routeLength.routeLength {
  width: 72px;
}

.inputLabel {
  margin-right: 14px;
}

@media (max-width: 400px) {
  .inputLabel > span {
    white-space: normal;
  }
}

.select {
  width: 56px;
}

.fieldContent {
  align-items: center;
  width: 72px;
}

.distanceUnit {
  margin-left: 10px;
}

@media (max-width: 1179px) {
  .label {
    white-space: normal;
  }
}
