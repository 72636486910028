.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px;
}

.itemsCount {
  color: var(--glz-color-neutral-tone-4);
  font-size: 12px;
}

.notifications {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  color: var(--glz-color-neutral-tone-4);
}

.notifications:only-child {
  margin-left: auto;
}

.warning {
  color: var(--glz-color-warning-tone-1);
}

.iconWrapper.iconWrapper {
  display: flex;
}
