.separator {
  position: absolute;
  top: 56px;
  width: calc(100% - 185px);
  height: 1px;
  background-color: var(--glz-color-neutral-tone-0);
  left: 185px;
}

.sortingTimeSelects {
  display: flex;
}

.sortingTimeSelects > :first-child {
  margin-right: 8px;
}

.firmsFilter {
  display: flex;
  margin-top: 12px;
  gap: 16px;
}

@media (max-width: 640px) {
  .firmsFilter {
    flex-wrap: wrap;
    margin-top: 0;
  }
}

.innerColumn {
  width: 100%;
  max-width: 232px;
}

@media (max-width: 640px) {
  .innerColumn {
    max-width: 100%;
  }
}

.field {
  margin-bottom: 8px;
}

@media (max-width: 640px) {
  .field {
    max-width: 100%;
    margin-bottom: 16px;
  }
}

.firmList {
  margin-bottom: 13px;
}

.listItem {
  margin-bottom: 8px;
}

.firmListNameContent {
  display: flex;
  gap: 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.toolbar {
  display: flex;
  justify-content: space-between;
}

.dropdown.dropdown.dropdown {
  max-width: 380px;
  min-width: 300px;
  max-height: 300px;
  padding: 8px;
  padding-right: 12px;
  overflow-y: auto;
}

.firmListName {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  font-size: 12px;
}

.firmDropdownList {
  padding-left: 12px;
  overflow-y: auto;
  overflow-x: hidden;
}

.firmName {
  height: 18px;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
}

.emoji {
  position: relative;
  top: 2px;
}

.onlyForPaid {
  margin-top: 12px;
}

@media (max-width: 890px) {
  .onlyForPaid {
    font-weight: normal;
  }
}

@media (max-width: 1179px) {
  .separator {
    left: 33%;
    width: calc(100% - 129px);
  }
}
