.groupOperations {
  display: flex;
  justify-content: space-between;
  padding: 10px 22px;
  width: 100%;
  border-radius: 8px 8px 0 0;
  background-color: var(--glz-color-success-tone-3);
  box-shadow:
    0 0 15px rgba(var(--glz-color-neutral-tone-5-rgb), 20%),
    0 0 4px rgba(var(--glz-color-neutral-tone-5-rgb), 10%);
}

@media (max-width: 540px) {
  .groupOperations {
    flex-wrap: wrap;
    gap: 16px;
  }
}

.infoTextContainer {
  display: flex;
  align-items: center;
}

.infoText {
  margin: 0;
  margin-right: 6px;
}

.iconButton {
  margin-top: 6px;
  cursor: pointer;
}

.operations {
  display: flex;
}

@media (max-width: 540px) {
  .operations {
    flex-basis: 100%;
  }
}

.secondaryOperation {
  margin-left: 12px;
}

@media (max-width: 540px) {
  .primaryOperation,
  .secondaryOperation {
    flex-grow: 1;
  }
}
