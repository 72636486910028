.container {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 24px;
}

.button {
  height: 28px;
}

@media (max-width: 890px) {
  .button:only-child {
    margin-right: 48px;
  }
}
