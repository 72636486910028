.list {
  width: 358px;
  font-size: 12px;
  letter-spacing: -0.1px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 300px;
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

@media (max-width: 890px) {
  .list {
    width: 272px;
  }
}

.item.item {
  padding: 0 10px;
}

.itemButton {
  border: none;
  background: transparent;
  width: calc(100% + 10px);
  line-height: 2.5;
  text-align: left;
  font-size: 12px;
  font-family: inherit;
  padding: 0 10px;
  cursor: inherit;
  outline: none;
}

.divider {
  margin: 0;
}
