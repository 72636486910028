.container {
  display: flex;
  flex-direction: column;
}

.container_isRow {
  flex-direction: row;
  align-items: center;
}

.label {
  font-size: 12px;
  height: 20px;
  display: flex;
  align-items: center;
  margin-bottom: 7px;
  padding-top: 3px;
}

.container_isRow .label {
  margin-right: 10px;
  margin-bottom: 0;
  padding-top: 0;
}

.container:last-child {
  margin-bottom: 0;
}

.label_bold {
  font-weight: bold;
}

.label_highlighted {
  color: var(--glz-color-success);
}

.content {
  display: flex;
}

.content_stretch > * {
  width: 100%;
}
