.board {
  padding: 4px 10px;
}

.boardList {
  margin-top: 1px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 300px;
}

.visibleBoard {
  margin-bottom: 8px;
}

@media (max-width: 890px) {
  .visibleBoard {
    margin-bottom: 16px;
  }
}

.board:last-child {
  padding-bottom: 8px;
  margin-bottom: 0;
}

.selectAllButton {
  padding: 4px 10px;
}

@media (max-width: 890px) {
  .selectAllButton {
    padding: 0;
    margin-bottom: 16px;
  }
}

.selectAllButton span {
  margin: initial;
}

.dropdownBoard {
  max-width: 100%;
}

.dropdownButton {
  margin-bottom: 10px;
}

.errorHeader {
  color: var(--glz-color-error);
}

.errorMessage {
  margin-bottom: 10px;
  white-space: pre-wrap;
}

.link.link {
  color: var(--glz-color-neutral-tone-4);
  margin-top: 19px;
  font-size: 11px;
}

.icon {
  margin-left: 4px;
}

@media (max-width: 890px) {
  .showAuction {
    margin-top: 24px;
  }
}
