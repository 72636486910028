.trial {
  max-width: 540px;
}

.special {
  display: inline-block;
  padding: 4px 8px;
  margin-bottom: 8px;
  border: 2px solid var(--glz-color-success);
  border-radius: 2px;
  color: var(--glz-color-neutral-tone-0);
  font-size: 11px;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: -0.3px;
  background-color: var(--glz-color-success);
  text-transform: capitalize;
}

.expiration {
  margin-top: 0;
}

.text {
  white-space: pre-line;
}
