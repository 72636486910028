.headerButton {
  padding: 15px 0;
  font-size: 14px;
  color: var(--glz-color-neutral-tone-5);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 100%;
}

.headerButton_isClickable {
  cursor: pointer;
}

.headerButton_isClickable:hover,
.headerButton_isClickable:active {
  color: var(--glz-color-primary);
}

.headerButton_highlighted {
  color: var(--glz-color-success);
}

.headerButton_error {
  color: var(--glz-color-error);
}

.headerButton_highlighted:hover,
.headerButton_highlighted:active {
  color: var(--glz-color-success-tone-1);
}

.arrow {
  min-width: 8px;
  min-height: 8px;
  border-left: 2px solid currentColor;
  border-bottom: 2px solid currentColor;
  display: inline-block;
  box-sizing: border-box;
  transform: rotate(-45deg);
  position: relative;
  top: -1px;
  opacity: 0.5;
}

.arrow_up {
  margin-top: 0;
  transform: rotate(135deg);
  top: 4px;
}

.arrow_right {
  transform: rotate(-135deg);
}

.imitateColumnBorder::after {
  display: block;
  content: '';
  width: 1px;
  height: 100%;
  background-color: var(--glz-color-neutral-tone-0);
  right: 0;
  position: absolute;
}
