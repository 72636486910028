.notifications {
  display: flex;
  align-items: center;
}

.warning {
  color: var(--glz-color-warning-tone-1);
}

@media (max-width: 890px) {
  .linkTextSecondPart {
    display: none;
  }
}
