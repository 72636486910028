.container {
  width: 100%;
}

.dropdownWrapper {
  width: 100%;
}

@media (max-width: 890px) {
  .dropdownWrapper {
    max-width: 288px;
  }
}

.input {
  position: relative;
  padding: 6px 25px 5px 10px;
  height: 32px;
  border: 1px solid var(--glz-color-neutral-tone-3);
  border-radius: 2px;
  background-color: var(--glz-color-neutral-tone-0);
}

.additionalAction {
  padding-left: 10px;
  padding-top: 14px;
  padding-bottom: 14px;
}

.input::after {
  content: '';
  pointer-events: none;
  position: absolute;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid var(--glz-color-neutral-tone-3);
  border-bottom: none;
  right: 8px;
  top: 14px;
  transition: all 0.15s ease-in-out;
}

.input_expanded::after {
  border-top: none;
  border-bottom: 5px solid var(--glz-color-neutral-tone-3);
}

.inputCaption {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  line-height: 18px;
  text-align: left;
}
