.additionalFilter {
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 1px;
  padding: 16px 17px 16px 12px;
}

.filterBackground {
  background-color: var(--filter-bg);
}

.darkFilterBackground {
  background-color: var(--filter-bg-dark);
}

.timeOption {
  padding: 8px 17px 8px 12px;
}
