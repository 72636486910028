@charset "UTF-8";
.tip_ae733d4a01 {
  position: relative;
  border-radius: 4px;
  outline: 0;
  transition-property: transform, visibility, opacity;
  /* dark */
  /* yellow */
  /* green */
  /* light */
}
.tip_ae733d4a01[data-animation=fade][data-state=hidden] {
  opacity: 0;
}
.tip_ae733d4a01[data-tippy-root] {
  max-width: calc(100vw - 10px);
}
.tip_ae733d4a01[data-placement^=top] > div:nth-child(2) {
  box-shadow: 0px 6px 12px 2px rgba(var(--glz-color-neutral-tone-5-rgb), 0.08);
  bottom: 0;
}
.tip_ae733d4a01[data-placement^=top] > div:nth-child(2)::before {
  bottom: -7px;
  left: 0;
  border-width: 8px 8px 0;
  border-top-color: initial;
  transform-origin: center top;
}
.tip_ae733d4a01[data-placement^=bottom] > div:nth-child(2) {
  box-shadow: 0px -6px 12px 2px rgba(var(--glz-color-neutral-tone-5-rgb), 0.08);
  top: 0;
}
.tip_ae733d4a01[data-placement^=bottom] > div:nth-child(2)::before {
  top: -7px;
  left: 0;
  border-width: 0 8px 8px;
  border-bottom-color: initial;
  transform-origin: center bottom;
}
.tip_ae733d4a01[data-placement^=left] > div:nth-child(2) {
  box-shadow: 6px 0px 12px 2px rgba(var(--glz-color-neutral-tone-5-rgb), 0.08);
  right: 0;
}
.tip_ae733d4a01[data-placement^=left] > div:nth-child(2)::before {
  border-width: 8px 0 8px 8px;
  border-left-color: initial;
  right: -7px;
  transform-origin: center left;
}
.tip_ae733d4a01[data-placement^=right] > div:nth-child(2) {
  box-shadow: -6px 0px 12px 2px rgba(var(--glz-color-neutral-tone-5-rgb), 0.16);
  left: 0;
}
.tip_ae733d4a01[data-placement^=right] > div:nth-child(2)::before {
  left: -7px;
  border-width: 8px 8px 8px 0;
  border-right-color: initial;
  transform-origin: center right;
}
.tip_ae733d4a01[data-inertia][data-state=visible] {
  transition-timing-function: cubic-bezier(0.54, 1.5, 0.38, 1.11);
}
.tip_ae733d4a01 > div:nth-child(2) {
  width: 16px;
  height: 16px;
}
.tip_ae733d4a01 > div:nth-child(2)::before {
  content: "";
  position: absolute;
  border-color: transparent;
  border-style: solid;
}
.tip_ae733d4a01 > div:nth-child(1) {
  position: relative;
  z-index: 1;
}
.tip_ae733d4a01[data-theme=dark] {
  background-color: var(--glz-color-neutral-tone-5);
  color: var(--glz-color-neutral-tone-0);
  border-radius: 2px;
  opacity: 0.9;
}
.tip_ae733d4a01[data-theme=dark] > div:nth-child(2) {
  /* убираем тень у стрелки на темной теме */
  box-shadow: none;
  color: var(--glz-color-neutral-tone-5);
}
.tip_ae733d4a01[data-theme=yellow] {
  background-color: var(--glz-color-warning-tone-3);
  color: var(--glz-color-neutral-tone-5);
  box-shadow: 0px 8px 24px rgba(var(--glz-color-neutral-tone-5-rgb), 0.16);
}
.tip_ae733d4a01[data-theme=yellow] > div:nth-child(2) {
  color: var(--glz-color-warning-tone-3);
}
.tip_ae733d4a01[data-theme=green] {
  background-color: var(--glz-color-success-tone-3);
  color: var(--glz-color-neutral-tone-5);
  box-shadow: 0px 8px 24px rgba(var(--glz-color-neutral-tone-5-rgb), 0.16);
}
.tip_ae733d4a01[data-theme=green] > div:nth-child(2) {
  color: var(--glz-color-success-tone-3);
}
.tip_ae733d4a01[data-theme=light] {
  background-color: var(--glz-color-neutral-tone-0);
  color: var(--glz-color-neutral-tone-5);
  box-shadow: 0px 8px 24px rgba(var(--glz-color-neutral-tone-5-rgb), 0.16);
}
.tip_ae733d4a01[data-theme=light] > div:nth-child(2) {
  color: var(--glz-color-neutral-tone-0);
}