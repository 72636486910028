.title {
  display: flex;
}

.heading.heading {
  letter-spacing: -0.4px;
  margin-top: 0;
  margin-bottom: 0;
}

.icon {
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  margin-right: 8px;
}

@media (max-width: 640px) {
  .icon {
    display: none;
  }

  .heading.heading {
    color: var(--glz-color-error);
  }
}

.icon::after {
  border-left-width: 6px;
  border-right-width: 6px;
}

.textContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
}

.text {
  margin-top: 0;
}

.link.link.link {
  font-size: 14px;
  line-height: 19px;
}

.sms {
  display: flex;
  align-items: center;
  margin-top: 8px;
  font-size: 14px;
  line-height: 16px;
  color: var(--glz-color-error);
}
