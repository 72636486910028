.volume {
  width: 100%;
  max-width: 142px;
}

.volumeInputs {
  height: 38px;
}

.volumeInputs label {
  height: 38px;
}

.sup {
  margin-bottom: 9px;
}

@media (max-width: 1179px) {
  .volume {
    max-width: 126px;
  }
}

@media (max-width: 890px) {
  .volume {
    max-width: 204px;
  }
}
