.container_6RNkj {
  font-family: Verdana, "Dejavu Sans", Geneva, Tahoma, sans-serif;
  display: flex;
  flex-direction: column;
}
.container_6RNkj .content-wrapper_VyMw5 {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 2px;
  background-color: var(--glz-color-neutral-tone-0);
  border: 1px solid var(--glz-color-neutral-tone-3);
  box-sizing: border-box;
  height: 100%;
}
.container_6RNkj .content-wrapper_VyMw5.error_w3EpH {
  background-color: var(--glz-color-error-tone-3);
  border-color: var(--glz-color-error);
}

.option-selector-popup_lVvM2 {
  font-family: Verdana, "Dejavu Sans", Geneva, Tahoma, sans-serif;
  display: flex;
  max-height: 100vh;
}
.option-selector-popup_lVvM2 .popup-header_UIT8b {
  padding: 23px 0 0 16px;
  display: flex;
  justify-content: space-between;
}
.option-selector-popup_lVvM2 .popup-search-block_O-EQW {
  padding: 0 16px;
}
.option-selector-popup_lVvM2 .popup-footer_btUNw {
  display: flex;
  margin-top: auto;
  justify-content: space-between;
  background-color: var(--glz-color-neutral);
  align-items: center;
  flex-direction: row;
}
.option-selector-popup_lVvM2 .popup-options-list_pqepA {
  display: flex;
  overflow-y: auto;
  flex-grow: 1;
}
.option-selector-popup_lVvM2 .popup-options-list_pqepA .type-list_q5Gi4 {
  padding-top: 8px;
  padding-left: 16px;
}

.search-float-button_gReil {
  position: absolute;
  right: 20px;
  top: 8px;
  background-color: rgba(var(--glz-color-neutral-tone-0-rgb), 0.5);
}

.search-block_pYli2 {
  background-color: var(--glz-color-neutral);
  padding: 8px 7px;
}

.input-close-button_WQTsS {
  background-color: var(--glz-color-neutral-tone-0);
  display: inline-flex;
  padding: 8px;
}

.mobile-view-input-lens-icon_XhWyn {
  fill: var(--glz-color-neutral-tone-4);
}

.search-float-button_gReil,
.input-close-button_WQTsS {
  border: 0;
  border-radius: 40px;
}
.search-float-button_gReil svg,
.input-close-button_WQTsS svg {
  fill: var(--glz-color-neutral-tone-4);
}
.search-float-button_gReil:hover,
.input-close-button_WQTsS:hover {
  cursor: pointer;
}
.search-float-button_gReil:hover svg,
.input-close-button_WQTsS:hover svg {
  fill: var(--glz-color-neutral-tone-5);
}
.search-float-button_gReil:focus-within,
.input-close-button_WQTsS:focus-within {
  outline: 0;
}
.search-float-button_gReil:focus-within svg,
.input-close-button_WQTsS:focus-within svg {
  fill: var(--glz-color-neutral-tone-5);
}

.type-list_q5Gi4 {
  padding: 0;
  margin: 0;
  list-style: none;
  width: 100%;
  font-size: 12px;
  box-sizing: border-box;
  overflow-y: auto;
}
.type-list_q5Gi4.search-open_SCEx0 {
  padding-top: 8px;
}
.type-list_q5Gi4.desktop-type-list_1LJe3 {
  padding: 13px 10px 0 10px;
}

.list-item_pu0no {
  position: relative;
  padding-bottom: 8px;
  list-style: none;
}
.list-item_pu0no.mobile_4wjiA {
  padding-bottom: 16px;
}

.checkbox-wrapper_y-abo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.checkbox-wrapper_y-abo.has-child_rxI5e {
  position: relative;
}
.checkbox-wrapper_y-abo.has-child_rxI5e::before {
  content: "";
  position: absolute;
  top: 12px;
  left: 6px;
  border-left: 1px solid var(--glz-color-neutral-tone-3);
  width: 7px;
  height: calc(100% - 12px);
}

.child_iEwYC {
  margin-left: 6px;
}

.child-list_3k7oY {
  padding: 0;
  margin: 0;
}
.child-list_3k7oY .list-item_pu0no {
  padding: 0 0 6px 7px;
}
.child-list_3k7oY .list-item_pu0no.mobile_4wjiA {
  padding: 0 0 16px 7px;
}
.child-list_3k7oY .list-item_pu0no::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border-bottom: 1px solid var(--glz-color-neutral-tone-3);
  width: 7px;
  height: 8px;
}
.child-list_3k7oY .list-item_pu0no:not(:last-child) {
  border-left: 1px solid var(--glz-color-neutral-tone-3);
}
.child-list_3k7oY .list-item_pu0no:first-child {
  padding-top: 8px;
  margin-top: -2px;
}
.child-list_3k7oY .list-item_pu0no:first-child::before {
  top: 3px;
  width: 7px;
  height: 13px;
}
.child-list_3k7oY .list-item_pu0no:first-child.mobile_4wjiA {
  padding-top: 15px;
  margin-top: 0;
}
.child-list_3k7oY .list-item_pu0no:first-child.mobile_4wjiA::before {
  top: 10px;
}
.child-list_3k7oY .list-item_pu0no:last-child {
  border-left: 1px solid transparent;
  padding-bottom: 0px;
}
.child-list_3k7oY .list-item_pu0no:last-child.mobile_4wjiA {
  padding-bottom: 2px;
}
.child-list_3k7oY .list-item_pu0no:last-child::before {
  left: -1px;
  width: 7px;
  height: 9px;
  border-left: 1px solid var(--glz-color-neutral-tone-3);
}

.popup-content_j6cAe {
  overflow: auto;
  display: flex;
  flex-grow: 1;
  padding-bottom: 0;
}

.no-results_BWsuD {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  height: 100%;
  width: 100%;
  margin: auto;
}
.no-results_BWsuD .no-results-text_FGgkT {
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 6px;
  color: var(--glz-color-neutral-tone-5);
}
.no-results_BWsuD .clear-button_12Fa6 {
  align-self: center;
}

.has-children_eZUtn > .checkbox-label_-wTHG {
  position: relative;
  padding-bottom: 1px;
}
.has-children_eZUtn > .checkbox-label_-wTHG::before {
  position: absolute;
  left: 8px;
  top: 8px;
  border-left: 1px solid var(--glz-color-neutral-tone-3);
  width: 0;
  bottom: 0;
  content: "";
  z-index: 0;
}

.simple-option_KsG5A {
  color: var(--glz-color-neutral-tone-5);
  cursor: pointer;
}