.field {
  width: 100%;
}

.label {
  font-size: 12px;
}

.autosuggest.autosuggest {
  height: 38px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input.input {
  height: 38px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.tooltip {
  width: 250px;
}

@media (max-width: 890px) {
  .tooltip {
    width: auto;
  }
}

.topRow {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.bottomRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.radiusLabel {
  justify-content: space-between;
}

.radiusLabelQuestion {
  margin-top: 3px;
}

.listIcon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
}

.suggestion {
  display: flex;
  align-items: center;
}

@media (max-width: 1179px) {
  .dropdown {
    min-width: calc(100% + 72px);
  }

  .hidablePart {
    display: none;
  }
}
