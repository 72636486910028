.suggestion-list-wrapper_ed973e084c {
  flex-direction: column;
  display: flex;
  box-sizing: border-box;
  box-shadow: 0 1px 2px rgba(var(--glz-color-neutral-tone-5-rgb), 0.21);
  height: fit-content;
  border: 1px solid var(--glz-color-neutral-tone-4);
  border-radius: 2px;
  background-color: var(--glz-color-neutral-tone-0);
  width: 100%;
}
.suggestion-list-wrapper_ed973e084c.is-absolute_ed973e084c {
  position: absolute;
}

.suggestion-list_ed973e084c {
  display: flex;
  flex-direction: column;
  max-height: 312px;
  height: fit-content;
  overscroll-behavior-y: contain;
  overflow: auto;
  box-sizing: border-box;
}
.suggestion-list_ed973e084c .ul-list_ed973e084c {
  padding: 0;
  margin: 0;
  list-style: none;
}
.suggestion-list_ed973e084c .ul-list_ed973e084c > li {
  margin: 0;
}

.fixed-element_ed973e084c {
  display: flex;
  align-items: center;
  padding: 10px;
  border: none;
  border-top: 1px solid var(--glz-color-neutral-tone-2);
  background-color: var(--glz-color-neutral-tone-0);
  cursor: pointer;
  user-select: none;
}
.fixed-element_ed973e084c:focus {
  outline: 0;
}

.suggestion_ed973e084c {
  display: flex;
  box-sizing: border-box;
  max-width: 100%;
  overflow: hidden;
}
.suggestion_ed973e084c.with-border-both_ed973e084c, .suggestion_ed973e084c.with-border-top_ed973e084c {
  border-top: 1px solid var(--glz-color-neutral-tone-2);
}
.suggestion_ed973e084c.with-border-both_ed973e084c, .suggestion_ed973e084c.with-border-bottom_ed973e084c {
  border-bottom: 1px solid var(--glz-color-neutral-tone-2);
}
.suggestion_ed973e084c .suggestion-checkbox_ed973e084c {
  padding: 8px;
  color: var(--glz-color-neutral-tone-5);
  width: 100%;
  user-select: none;
}
.suggestion_ed973e084c .suggestion-checkbox_ed973e084c input + span {
  padding-left: 19px;
}
.suggestion_ed973e084c .suggestion-checkbox_ed973e084c > span {
  width: 100%;
  padding-left: 22px;
}
.suggestion_ed973e084c .suggestion-checkbox_ed973e084c > input + span::before {
  top: 0;
  margin: 2px;
}
.suggestion_ed973e084c .suggestion-checkbox_ed973e084c > input:checked + span::after {
  top: 5px;
  left: 4px;
}
.suggestion_ed973e084c .suggestion-checkbox_ed973e084c.is-selected_ed973e084c {
  background-color: var(--glz-color-primary-tone-3);
}
.suggestion_ed973e084c .suggestion-button_ed973e084c {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  padding: 8px;
  width: 100%;
  color: var(--glz-color-neutral-tone-5);
  text-align: left;
  font-family: Verdana, "Dejavu Sans", Geneva, Tahoma, sans-serif;
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;
  user-select: none;
}
.suggestion_ed973e084c .suggestion-button_ed973e084c.with-additional-padding_ed973e084c {
  padding: 10px 8px;
}
.suggestion_ed973e084c .suggestion-button_ed973e084c[disabled] {
  color: var(--glz-color-neutral-tone-3);
  cursor: not-allowed;
}
.suggestion_ed973e084c .suggestion-button_ed973e084c[disabled] .highlight_ed973e084c {
  color: var(--glz-color-neutral-tone-3);
}
.suggestion_ed973e084c .suggestion-button_ed973e084c.suggestion-message_ed973e084c {
  cursor: default;
}
.suggestion_ed973e084c .suggestion-button_ed973e084c:focus {
  outline: 0;
}
.suggestion_ed973e084c .suggestion-button_ed973e084c.is-selected_ed973e084c {
  background-color: var(--glz-color-primary-tone-3);
}
.suggestion_ed973e084c .suggestion-button_ed973e084c:not([disabled]):not(.suggestion-message_ed973e084c):focus-visible, .suggestion_ed973e084c .suggestion-button_ed973e084c:not([disabled]):not(.suggestion-message_ed973e084c):hover {
  background-color: var(--glz-color-primary-tone-3);
}
.suggestion_ed973e084c .suggestion-button_ed973e084c:not([disabled]):not(.suggestion-message_ed973e084c):active {
  background-color: var(--glz-color-primary-tone-4);
}
.suggestion_ed973e084c .before_ed973e084c {
  display: inline-flex;
  align-self: center;
  margin-right: 4px;
}
.suggestion_ed973e084c .highlight_ed973e084c {
  font-weight: 700;
  color: var(--glz-color-neutral-tone-5);
  background-color: transparent;
  text-decoration: underline;
}

.suggestion-text_ed973e084c {
  display: inline-flex;
  align-items: center;
}

.message-icon_ed973e084c {
  display: inline-flex;
  height: 16px;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
}

.message-text_ed973e084c {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  font-family: Verdana, "Dejavu Sans", Geneva, Tahoma, sans-serif;
  color: var(--glz-color-neutral-tone-4);
  user-select: none;
}

.select-button_ed973e084c {
  max-height: 32px;
  box-sizing: border-box;
}

.remove-button_ed973e084c {
  max-height: 32px;
  box-sizing: border-box;
}
.remove-button_ed973e084c .icon_ed973e084c {
  margin-right: 4px;
}