.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  padding-right: 60px;
}

.header_wide {
  padding-right: 0;
}

.title {
  margin-top: 0;
  color: var(--glz-color-success);
}

.subscribeCheckbox {
  max-width: max-content;
  margin-bottom: 0;
}

.subscribeCheckboxContent.subscribeCheckboxContent {
  display: inline;
}

.subscribeText {
  white-space: pre-wrap;
}

.free {
  margin-left: 4px;
  font-size: 12px;
  line-height: 16px;
  font-style: normal;
  font-weight: 700;
  color: var(--glz-color-success);
}

.channels {
  display: flex;
  flex-direction: column;
  padding-left: 26px;
}

.plusButton {
  margin-bottom: 8px;
}

.smsCheckboxContainer {
  display: flex;
  flex-direction: column;
}

.smsCostContainer {
  padding-left: 18px;
  font-size: 12px;
}

.smsCost {
  padding: 0 4px;
  border-radius: 4px;
  background-color: var(--glz-color-warning);
}

[data-theme='dark-theme'] .smsCost {
  color: var(--glz-color-neutral);
}

/* TODO: перебиваем стили нашего чекбокса, от такого бы избавляться, но пока так */
.smsCheckbox > span {
  margin-bottom: 4px;
}

.additionalNotifications {
  display: flex;
  align-items: center;
  gap: 4px;
}
