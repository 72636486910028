.popover {
  max-width: 235px;
}

@media (max-width: 480px) {
  .popover {
    max-width: none;
  }
}

.title {
  text-align: left;
}

.actions.actions {
  padding-top: 0;
}

.alternateAction {
  height: 28px;
}
