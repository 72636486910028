.container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 100px;
}

.heading.heading {
  margin-bottom: 15px;
  letter-spacing: -0.2px;
  font-size: 21px;
  line-height: 1.2;
}

.destinationsContainer {
  width: 100%;
}

.destinations {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  grid-row-gap: 6px;
}

@media (max-width: 1200px) {
  .destinations {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 890px) {
  .destinations {
    grid-template-columns: 1fr;
  }
}
