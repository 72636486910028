.rateContainer {
  margin-top: 8px;
  margin-bottom: 8px;
}

.currency {
  display: flex;
  align-items: center;
}

.currencyLabel {
  font-size: 12px;
  line-height: 16px;
  margin-right: 4px;
}

.currencyToggleButton {
  white-space: nowrap;
}

.currencyDropdown,
.ratePerKmLabel {
  width: 77px;
}

.ratePerKmLabel {
  padding-left: 8px;
  font-size: 12px;
  line-height: 16px;
}

.currency,
.rateTotal,
.ratePerKm {
  display: flex;
  flex-wrap: wrap;
  row-gap: 8px;
  margin-bottom: 8px;
}

@media (max-width: 890px) {
  .currency,
  .rateTotal,
  .ratePerKm {
    max-width: 288px;
  }
}

.ratePerKm {
  display: flex;
  align-items: center;
}

.rateInputWrapper {
  flex-basis: 82px;
  flex-shrink: 0;
  flex-grow: 1;
}

.rateTypeSelect.rateTypeSelect.rateTypeSelect,
.ratePerKmLabel {
  flex-basis: 77px;
  flex-shrink: 0;
  flex-grow: 1;
}

.rateTypesInnerSelect :global(.select__menu-list) {
  overflow-x: hidden;
}
