.container {
  display: flex;
}

.maxInput.maxInput {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.minInput.minInput {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
