.container {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 32px;
  box-shadow: rgb(var(--glz-color-neutral-tone-5-rgb), 30%) 0 0 16px 0;
  background-color: var(--stiky-tabs-bg);
  width: 100%;
  max-width: 1160px;
  padding-right: 12px;
  transform: translate(-10px, 0);
  z-index: 1500;
}

@media (max-width: 1140px) {
  .container {
    display: none;
  }
}

.tabs {
  display: flex;
}

.action {
  display: flex;
  align-items: center;
  margin-left: 4px;
}

.subscribeButton {
  margin-left: auto;
}

.refreshIcon {
  margin-left: 4px;
  margin-right: 5px;
  opacity: 0.5;
}

.stickyTab {
  border-radius: 0;
  background: none;
  font-size: 13px;
  box-shadow: none;
  height: 48px;
  min-width: 0;
}

.stickyTab__hy {
  font-size: 11px;
  padding-left: 8px;
  padding-right: 8px;
}
