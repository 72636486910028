.container {
  padding: 0 2rem;
}

.layout {
  display: flex;
  width: 100%;
  padding-left: var(--body-paddings);
  padding-right: var(--body-paddings);
}

@media (max-width: 890px) {
  .layout {
    background-color: var(--glz-color-neutral);
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 1560px) {
  .layout {
    padding-right: 0;
  }
}

.main {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1138px;
  margin: 0 auto;
}

.search {
  width: 100%;
}

.panel {
  position: sticky;
  bottom: 0;
  display: flex;
  width: 100%;
}

.media {
  width: 100%;
}

@media (max-width: 890px) {
  .media {
    padding-right: var(--body-paddings);
    padding-left: var(--body-paddings);
  }
}

@media (max-width: 1560px) {
  .asideMedia {
    display: none;
  }
}

@media (min-width: 1560px) {
  .mediaSkeleton {
    display: none;
  }
}
