@media (max-width: 1300px) {
  .container {
    display: none;
  }
}

.button {
  cursor: pointer;
  position: absolute;
  right: -64px;
  bottom: 0;
  transition: right 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: var(--glz-color-neutral-tone-0);
  box-shadow: 0 0 15px 0 rgba(var(--glz-color-neutral-tone-5-rgb), 0.2);
}

@media (max-width: 1300px) {
  .button {
    right: 0;
  }
}
