.container {
  display: inline-flex;
  align-self: center;
  width: 16px;
  height: 16px;
  cursor: pointer;
  margin-left: 4px;
  border-radius: 50%;
}

.text {
  white-space: pre-wrap;
}

.icon.icon.icon {
  display: inline-flex;
}

.title {
  display: inline-block;
  margin-bottom: 3px;
}
