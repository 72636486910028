.container {
  display: flex;
}

.wrapper {
  display: inline-flex;
  margin-left: 4px;
}

.icon {
  display: flex;
  align-items: center;
}

.warning {
  margin-top: 0;
  padding-left: 26px;
  color: var(--glz-color-warning-tone-2);
}
