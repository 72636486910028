.summary {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding: 20px;
  gap: 8px;
  border-radius: 4px;
  background-color: var(--glz-color-neutral);
}

@media (max-width: 640px) {
  .summary {
    padding: 12px;
  }
}
