.details {
  display: grid;
  grid-template-columns: 140px auto;
  grid-row-gap: 5px;
  font-size: 12px;
}

.type {
  color: var(--glz-color-neutral-tone-4);
}

.value {
  color: var(--glz-color-neutral-tone-4);
}

.toggle {
  align-self: flex-start;
}
