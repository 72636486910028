.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 48px 40px;
}

.title,
.description {
  margin: 0;
  text-align: center;
}

.descriptionContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-bottom: 24px;
  max-width: 450px;
}

.description {
  color: var(--glz-color-neutral-tone-4);
}
