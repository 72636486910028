.container {
  position: relative;
  display: flex;
  align-items: center;
}

.button {
  font-display: fallback;
  margin-right: 10px;
}

.text {
  font-size: 11px;
  max-width: 140px;
  display: inline-block;
  color: var(--glz-color-neutral-tone-4);
}

@media (max-width: 530px) {
  .text {
    max-width: initial;
  }
}

.popover.popover {
  max-width: 370px;
  background-color: var(--glz-color-success-tone-3);
}

.contentWrapper.contentWrapper {
  padding-top: 16px;
  padding-bottom: 16px;
}

.content.content.content {
  display: flex;
  gap: 16px;
  padding-left: 16px;
  padding-right: 48px;
}

.promoTextContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.promoTextSubscribe {
  margin-top: 0;
}

.promoTextFree {
  display: flex;
  align-self: flex-start;
  padding: 4px 8px;
  border-radius: 2px;
  background-color: var(--glz-color-success);
  color: var(--glz-color-neutral-tone-0);
}

.closeButton.closeButton {
  top: 16px;
  right: 16px;
}
