.weight {
  width: 100%;
  max-width: 142px;
}

.weightInputs {
  height: 38px;
}

.weightInputs label {
  height: 38px;
}

@media (max-width: 1179px) {
  .weight {
    max-width: 126px;
  }
}

@media (max-width: 890px) {
  .weight {
    max-width: 204px;
  }
}
