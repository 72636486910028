.select_409aff4f19 {
  position: relative;
  width: 100%;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
}

.select-input_409aff4f19 {
  position: relative;
  height: 32px;
  border: 1px solid var(--glz-color-neutral-tone-3);
  background-color: var(--glz-color-neutral-tone-0);
  border-radius: 2px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  color: var(--glz-color-neutral-tone-5);
  justify-content: space-between;
  cursor: pointer;
  width: 100%;
  touch-action: manipulation;
}
.select-input_409aff4f19:focus-visible {
  border: 1px solid var(--glz-color-neutral-tone-4);
  box-shadow: 0 1px 2px rgba(var(--glz-color-neutral-tone-5-rgb), 0.21);
}
.select-input_409aff4f19:hover {
  border: 1px solid var(--glz-color-neutral-tone-4);
}
.select-input_409aff4f19:focus-within {
  border: 1px solid var(--glz-color-neutral-tone-4);
  box-shadow: 0 1px 2px rgba(var(--glz-color-neutral-tone-5-rgb), 0.21);
  outline: 0;
}
.select-input_409aff4f19.is-error_409aff4f19 {
  background-color: var(--glz-color-error-tone-3);
  border: 1px solid var(--glz-color-error);
}
.select-input_409aff4f19.is-error_409aff4f19:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 500px var(--glz-color-error-tone-3) inset;
}
.select-input_409aff4f19.is-warning_409aff4f19 {
  border-bottom-color: var(--glz-color-warning);
  box-shadow: inset 0 -1px 0 var(--glz-color-warning);
}
.select-input_409aff4f19.is-warning_409aff4f19:focus-within {
  border-bottom-color: var(--glz-color-warning);
  box-shadow: inset 0 -1px 0 var(--glz-color-warning);
}
.select-input_409aff4f19 .input-wrapper_409aff4f19 {
  display: inline-flex;
  align-items: center;
  flex-grow: 1;
  max-width: calc(100% - 24px);
  padding: 0 8px;
  box-sizing: border-box;
}
.select-input_409aff4f19 .input-wrapper_409aff4f19 .input-text_409aff4f19,
.select-input_409aff4f19 .input-wrapper_409aff4f19 .counter_409aff4f19 {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  font-family: Verdana, "Dejavu Sans", Geneva, Tahoma, sans-serif;
  user-select: none;
}
.select-input_409aff4f19 .input-wrapper_409aff4f19 .counter_409aff4f19 {
  margin-left: 3px;
}
.select-input_409aff4f19 .input-wrapper_409aff4f19 .input-text_409aff4f19 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
}
.select-input_409aff4f19 .input-wrapper_409aff4f19.value-text_409aff4f19 .input-text_409aff4f19 {
  color: var(--glz-color-neutral-tone-5);
}
.select-input_409aff4f19 .input-wrapper_409aff4f19.placeholder-text_409aff4f19 .input-text_409aff4f19 {
  color: var(--glz-color-neutral-tone-3);
}
.select-input_409aff4f19 .before_409aff4f19, .select-input_409aff4f19 .after-icons_409aff4f19 {
  display: inline-flex;
  align-self: center;
  color: var(--glz-color-neutral-tone-4);
}
.select-input_409aff4f19 .before_409aff4f19 svg, .select-input_409aff4f19 .after-icons_409aff4f19 svg {
  fill: var(--glz-color-neutral-tone-4);
}
.select-input_409aff4f19 .after-icons_409aff4f19 {
  margin-right: 8px;
  height: 100%;
  display: inline-flex;
  align-items: center;
}
.select-input_409aff4f19 .after-icons_409aff4f19 .after_409aff4f19 {
  margin-right: 4px;
  display: inline-flex;
}
.select-input_409aff4f19 .after-icons_409aff4f19 svg {
  fill: var(--glz-color-neutral-tone-4);
}
.select-input_409aff4f19 .measure_409aff4f19 {
  display: inline-flex;
  align-self: center;
  font-family: Verdana, "Dejavu Sans", Geneva, Tahoma, sans-serif;
  font-size: 12px;
  line-height: 16px;
  color: var(--glz-color-neutral-tone-5);
  margin-right: 7px;
}
.select-input_409aff4f19 .before_409aff4f19 {
  margin-right: 4px;
}
.select-input_409aff4f19 .dummy-input_409aff4f19 {
  background: 0px center;
  border: 0px;
  font-size: inherit;
  outline: 0px;
  padding: 0px;
  width: 1px;
  color: transparent;
  left: -100px;
  opacity: 0;
  position: relative;
  transform: scale(0);
}
.select-input_409aff4f19.is-disabled_409aff4f19 {
  background-color: var(--glz-color-neutral);
  border: 1px solid var(--glz-color-neutral-tone-3);
  cursor: not-allowed;
  color: var(--glz-color-neutral-tone-4);
}
.select-input_409aff4f19.is-disabled_409aff4f19 .before_409aff4f19, .select-input_409aff4f19.is-disabled_409aff4f19 .after-icons_409aff4f19 {
  color: var(--glz-color-neutral-tone-3);
}
.select-input_409aff4f19.is-disabled_409aff4f19 .before_409aff4f19 svg, .select-input_409aff4f19.is-disabled_409aff4f19 .after-icons_409aff4f19 svg {
  fill: var(--glz-color-neutral-tone-3);
}