.geo {
  display: flex;
}

.geoName {
  display: inline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.geoName_disabled,
.geoCount_disabled {
  color: var(--glz-color-neutral-tone-3);
  cursor: not-allowed;
}

.additionalAction {
  padding: 11px 20px;
}

.geoCount {
  margin-left: 5px;
  opacity: 0.5;
  flex-grow: 1;
  padding-right: 20px;
}

.editButton {
  cursor: pointer;
  display: none;
  padding: 0 8px;
}

.geo:hover .editButton {
  display: block;
}

.tooltipWrapper {
  display: flex;
  align-items: center;
  flex-grow: 1;
  max-width: calc(100% - 24px);
}

@media (max-width: 1179px) {
  .chooseListFirstPart {
    display: none;
  }

  .chooseListSecondPart {
    text-transform: capitalize;
  }
}
