.form-field_gfj77 {
  width: 100%;
  max-width: var(--size);
  font-family: Verdana, "Dejavu Sans", Geneva, Tahoma, sans-serif;
}

.labeled-field_Tik2R {
  display: flex;
  flex-direction: column;
  position: relative;
}
.labeled-field_Tik2R.is-required_c3ewa::before {
  content: "*";
  position: absolute;
  top: 1px;
  left: -12px;
  color: #d0021b;
  font-size: 14px;
}

.xxlarge_Bne7I {
  max-width: 472px;
}

.xlarge_phS-3 {
  max-width: 392px;
}

@media (max-width: 540px) {
  .xxlarge_Bne7I,
  .xlarge_phS-3 {
    max-width: 100%;
  }
}

.large_1df-8 {
  max-width: 312px;
}

.medium_4MNnU {
  max-width: 232px;
}

.small_J-rha {
  max-width: 152px;
}

.xsmall_Dt-U- {
  max-width: 72px;
}

.distance-8_GTdza {
  padding-right: 0;
}

.distance-16_ucYGd {
  padding-right: 8px;
}

.distance-24_LRZrj {
  padding-right: 16px;
}

.distance-32_EyOJQ {
  padding-right: 24px;
}

.distance-48_OMkql {
  padding-right: 40px;
}

.distance-64_a8401 {
  padding-right: 56px;
}

.field-label_XTtje {
  font-size: 12px;
  line-height: 16px;
  height: 16px;
  margin-bottom: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}