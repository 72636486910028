.dropdown-button-wrapper_vTb-d {
  display: flex;
  align-items: center;
}

.active_DDiCk.is-splited_htmnu .trigger_nqu09 {
  background-color: var(--glz-color-primary-tone-2) !important;
}
.active_DDiCk.is-splited_htmnu .trigger_nqu09.is-error_imtye {
  background-color: var(--glz-color-error-tone-2) !important;
}
.active_DDiCk.is-splited_htmnu .trigger_nqu09.is-secondary_-AJG1 {
  background-color: var(--glz-color-success-tone-2) !important;
}
.active_DDiCk:not(.is-splited_htmnu) .button_xMZ8T {
  background-color: var(--glz-color-primary-tone-2) !important;
}
.active_DDiCk:not(.is-splited_htmnu) .button_xMZ8T.is-error_imtye {
  background-color: var(--glz-color-error-tone-2) !important;
}
.active_DDiCk:not(.is-splited_htmnu) .button_xMZ8T.is-secondary_-AJG1 {
  background-color: var(--glz-color-success-tone-2) !important;
}
.active_DDiCk .arrow_mDmMc {
  transform: rotate(180deg);
}

.is-splited_htmnu {
  position: relative;
  width: fit-content;
}
.is-splited_htmnu .dropdown-button_-XaqE {
  position: unset;
}
.is-splited_htmnu .trigger_nqu09.trigger_nqu09.trigger_nqu09.trigger_nqu09 {
  display: flex;
  padding: 0;
  position: unset;
  width: 47px;
}
.is-splited_htmnu .trigger_nqu09.trigger_nqu09.trigger_nqu09.trigger_nqu09.is-large_NkVMh {
  width: 55px;
}
.is-splited_htmnu .trigger_nqu09.trigger_nqu09.trigger_nqu09.trigger_nqu09.is-small_sVF4- {
  width: 39px;
}
.is-splited_htmnu .arrow_mDmMc {
  fill: var(--glz-color-neutral-tone-0);
}

.trigger_nqu09 {
  display: block;
  background-color: inherit;
  margin-left: 1px;
}

.list-wrapper_HnxsX {
  width: 100%;
  margin-top: 0;
}

.dropdown_moLLZ {
  width: 100%;
}