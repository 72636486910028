.icon_c381a1e4dc {
  cursor: pointer;
  display: inline-flex;
  background: none;
  border: none;
  padding: 0;
}
.icon_c381a1e4dc .active_c381a1e4dc {
  display: none;
}
.icon_c381a1e4dc .default_c381a1e4dc {
  display: inline-flex;
}
.icon_c381a1e4dc.is-active_c381a1e4dc .default_c381a1e4dc, .icon_c381a1e4dc:hover .default_c381a1e4dc, .icon_c381a1e4dc:focus-visible .default_c381a1e4dc {
  display: none;
}
.icon_c381a1e4dc.is-active_c381a1e4dc .active_c381a1e4dc, .icon_c381a1e4dc:hover .active_c381a1e4dc, .icon_c381a1e4dc:focus-visible .active_c381a1e4dc {
  display: inline-flex;
}
.icon_c381a1e4dc:focus {
  outline: 0;
}