.icon {
  font-weight: 400;
  display: inline-flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 16px;
  height: 16px;
  color: var(--glz-color-neutral-tone-0);
  font-size: 14px;
  margin-right: 3px;
}

.stop::before {
  content: '';
}

.stop::after {
  content: '';
  border-color: var(--glz-color-neutral-tone-0);
  border-left: 2px solid var(--glz-color-neutral-tone-0);
  border-right: 2px solid var(--glz-color-neutral-tone-0);
  width: calc(100% / 2);
  height: calc(100% / 2);
}

.exclamation::before {
  content: '!';
  color: var(--glz-color-neutral-tone-0);
}

.critical {
  background-color: var(--glz-color-error);
}

.warning {
  background-color: var(--glz-color-warning-tone-1);
}

.tip {
  font-size: 9px;
  letter-spacing: -0.3px;
  margin-bottom: 10px;
  white-space: pre-wrap;
  display: flex;
  flex-direction: column;
}

.tip:last-child {
  margin-bottom: 0;
}
