.tab {
  display: flex;
  height: 56px;
  min-width: 126px;
  padding: 5px 16px;
  font-size: 16px;
  line-height: 20px;
  justify-content: center;
  align-items: center;
  border-radius: 8px 8px 0 0;
  color: var(--glz-color-neutral-tone-4);
  cursor: pointer;
  white-space: nowrap;
}

.tab:hover {
  color: var(--glz-color-neutral-tone-5);
}

/* вкладки не помещаются до перехода в скролл-режим, поэтому мы уменьшаем отступы чуть раньше  */
@media (max-width: 1179px) {
  .tab {
    padding-left: 12px;
    padding-right: 12px;
    font-size: 15px;
  }
}

@media (max-width: 890px) {
  .tab {
    height: auto;
    padding: 12px;
    font-size: 12px;
    line-height: 16px;
    color: var(--glz-color-neutral-tone-5);
  }
}

.tab_active {
  background-color: var(--filter-bg);
  color: var(--glz-color-neutral-tone-5);
  box-shadow: 0 1px 2px rgba(var(--glz-color-neutral-tone-5-rgb), 20%);
}

@media (max-width: 890px) {
  .tab_active {
    background-color: transparent;
    box-shadow: inset 0 -6px 0 -2px var(--glz-color-primary);
  }
}

.tab_special {
  position: relative;
  column-gap: 8px;
  background-color: var(--glz-color-primary-tone-3);
  color: var(--glz-color-neutral-tone-5);
  box-shadow: 0 1px 2px rgb(var(--glz-color-neutral-tone-5-rgb), 20%);
}

.tab_special::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.tab_special:hover::after {
  background-color: rgba(var(--glz-color-neutral-tone-5-rgb), 0.03);
}

@media (max-width: 1179px) {
  .tab_special {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media (max-width: 890px) {
  .tab_special {
    background-color: transparent;
    box-shadow: none;
  }

  .tab_special.tab_active {
    box-shadow: inset 0 -6px 0 -2px var(--glz-color-primary);
  }

  .tab_special:hover {
    background-color: transparent;
  }

  .badge {
    display: none;
  }
}
