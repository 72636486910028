.dropdownToggle {
  margin-left: auto;
  height: 48px;
  justify-content: center;
  padding-right: 4px;
  padding-left: 4px;
}

.list.list {
  min-width: 200px;
  width: auto;
  margin-top: -5px;
}

.actionsButton {
  cursor: pointer;
  display: flex;
  width: 24px;
  height: 48px;
  align-items: center;
  justify-content: center;
  transform: rotate(90deg);
  color: var(--glz-color-neutral-tone-3);
}

.actionsButton:hover {
  color: var(--glz-color-primary-tone-2);
}

.action {
  display: flex;
  align-items: center;
}

.icon {
  margin-right: 4px;
}
