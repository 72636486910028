.select {
  display: none;
  max-width: 272px;
}

@media (max-width: 679px) {
  .buttonGroup {
    display: none;
  }

  .select {
    display: block;
  }
}
