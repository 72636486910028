.section {
  margin: 0;
  font-size: 12px;
  line-height: 16px;
  color: var(--glz-color-neutral-tone-5);
}

.value {
  font-weight: 700;
  white-space: nowrap;
}
