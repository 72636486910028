.container {
  height: 48px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 8px 0 8px 12px;
  position: relative;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(var(--glz-color-neutral-tone-5-rgb), 20%);
  background-color: var(--glz-color-neutral-tone-0);
}

.container_subscribed {
  background-color: var(--glz-color-success-tone-3);
}

.container_error {
  background-color: var(--glz-color-error-tone-3);
}

.container_noActions {
  padding-right: 8px;
}

.container:hover {
  background-color: var(--glz-color-primary-tone-3);
}

.container:nth-child(5) {
  border-bottom: none;
}

.content {
  display: inline-flex;
  flex-direction: column;
  padding-right: 4px;
  font-size: 12px;
  line-height: 16px;
}

.name,
.errorDesc {
  /* у line-clamp норм поддержка, но только в таком виде
   * https://developer.mozilla.org/en-US/docs/Web/CSS/-webkit-line-clamp
   */
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: anywhere;
}

.name_oneLine {
  -webkit-line-clamp: 1;
}

.errorDesc {
  margin-top: 4px;
  font-size: 11px;
  line-height: 12px;
  color: var(--glz-color-error);
  -webkit-line-clamp: 1;
}
